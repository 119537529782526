class FurtherExplanations {
    hideShow(resultObj) {

        if (
            ((resultObj['kosyInfos'] && resultObj['kosyInfos'].length > 0)|| resultObj['generalInfos'])
            && resultObj['topicId'] !== 17
        ) {
            $('#topic-inputs').append("<div class=\"notes-demand\">Nähere Erläuterung</div>");
        } else {
            if (resultObj['topicId'] !== 17) {
                $('.notes-demand').remove();
            }
        }
    }
}

export default FurtherExplanations;
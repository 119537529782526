import {DataAttributes, DomClasses, DomIds} from '../utils/DomConstants';

class HintForAnswer {
    renderHintForOneAnswer(resultObj) {
        const topicContentsNode = document.getElementById(DomIds.TOPIC_INPUTS);
        const questions = resultObj['questions'];
        const hasOnlyOneTextKey = 'hasOnlyOneAnswerText';
        let text = `<div class="${DomClasses.HINT_ONLY_ONE_ANSWER}">`;
        let countOfHints = 0;

        for (const questionsKey in questions) {
            if (questions.hasOwnProperty(questionsKey) && questions[questionsKey].hasOwnProperty(hasOnlyOneTextKey)) {
                const question = questions[questionsKey];
                const texts = question[hasOnlyOneTextKey];
                for (const textsKey in texts) {
                    if (texts.hasOwnProperty(textsKey) && this.onlyConnectedAnswerIsVisible(question, textsKey)) {
                        text += `<div>${texts[textsKey]}</div>`;
                        countOfHints++;
                    }
                }

            }
        }

        text += '</div>';

        if (countOfHints > 0) {
            topicContentsNode.insertAdjacentHTML('beforeend', text);
        }
    }

    onlyConnectedAnswerIsVisible(question, textKey) {
        const visibleAnswers = [];
        const answers = question['answers'];
        for (const answersKey in answers) {
            if (answers.hasOwnProperty(answersKey)
                && answers[answersKey].hasOwnProperty('currentVisibility')
                && answers[answersKey]['currentVisibility']
            ) {
                visibleAnswers.push(answers[answersKey].id);
            }
        }

        if (textKey === 'all') {
            return visibleAnswers.length === 1;
        }

        return visibleAnswers.length === 1 && visibleAnswers.includes(parseInt(textKey));
    }

    renderHintOnClickIfOtherAnswersShown(resultObj) {
        const questions = resultObj['questions'];
        const hintKey = 'hintOnClickIfOtherAnswersShown';
        const topicContentsNode = document.getElementById(DomIds.TOPIC_INPUTS);

        for (const questionsKey in questions) {
            let questionAnswered = false;
            let showHint = false;
            let countOfHints = 0;

            if (questions.hasOwnProperty(questionsKey)) {

                const answers = questions[questionsKey]['answers'];
                let text = '';
                let contOfAnswers = 0;
                let classNames = DomClasses.HINT_IF_OTHER_ANSWERS_SHOWN;
                let dataValueForClickedAnswer = '';
                for (const answersKey in answers) {
                    if (answers.hasOwnProperty(answersKey)) {
                        if (answers[answersKey]['currentVisibility']) {
                            contOfAnswers++;
                        }
                        if ( answers[answersKey].hasOwnProperty(hintKey)) {
                            text += answers[answersKey][hintKey];
                            countOfHints++;
                            dataValueForClickedAnswer = answers[answersKey]['id'];
                            if (answers[answersKey]['checked'] === false) {
                                classNames += ' hidden';
                            }
                        }
                    }

                    if (!questionAnswered && answers[answersKey]['checked']) {
                        questionAnswered = true;
                    }
                }
                showHint = contOfAnswers > 1;

                if (showHint && countOfHints > 0) {
                    let html = `<div class="${classNames}" data-${DataAttributes.HINT_OTHER_ANSWERS}="${dataValueForClickedAnswer}">${text}</div>`;
                    topicContentsNode.insertAdjacentHTML('beforeend', html);
                }
            }
        }
    }

    renderHintForSummarySlope(resultObj) {
        const key = 'summarySlopeText';
        if (resultObj.hasOwnProperty(key) && resultObj[key] !== '') {
            const topicContentsNode = document.getElementById(DomIds.TOPIC_INPUTS);
            const text = `<div class="${DomClasses.HINT_SUMMARY_SLOPE_TEXT}">${resultObj[key]}</div>`;
            topicContentsNode.insertAdjacentHTML('beforeend', text);
        } else {
            const node = document.querySelector('.' + DomClasses.HINT_SUMMARY_SLOPE_TEXT);
            if (node) {
                node.classList.add('hidden');
            }
        }
    }

    static hideShowHintOnClickIfOtherAnswersShown(clickedAnswerId) {
        const node = document.querySelector('[data-' + DataAttributes.HINT_OTHER_ANSWERS + ']');
        if (node) {
            if (node.dataset[DataAttributes.HINT_OTHER_ANSWERS] === clickedAnswerId) {
                node.classList.remove('hidden');
            } else {
                node.classList.add('hidden');
            }
        }
    }
}

export default HintForAnswer;
class KosyErrorWidth {
    static calculateWidth() {
        var topicDescriptionWidth = $('.topic-description').width();
        var kosyWidth = $('#kosy-image').width();
        if(!kosyWidth) {
            kosyWidth = 0;
        }
        var kosyErrorWidth = $('#kosy-image-error').width();
        if(!kosyErrorWidth) {
            kosyErrorWidth = 0;
        }
        var kosyInfoWidth = topicDescriptionWidth-kosyWidth - 220;
        var kosyInfoErrorWidth = topicDescriptionWidth-kosyErrorWidth - 155;
        $('#kosy-info').css('width', kosyInfoWidth+'px');
        $('#kosy-error').css('width', kosyInfoErrorWidth+'px');
    }
}

export default KosyErrorWidth;
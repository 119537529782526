class Fetch {
    constructor (url, method, headers = {}, data) {
        this.url = window.location.origin + '/' + url;
        this.method = method;
        this.initParams = {
            method: this.method,
            headers: headers,
            mode: 'cors',
            cache: 'default'
        };

        if (data) {
            this.initParams['body'] = data;
        }
    }

    proceed (action) {
        fetch(this.url, this.initParams)
            .then((response) => {
                if (this.method !== 'POST') {
                    return response.json();
                }
            })
            .then(action)
            .catch(function (error) {
                console.error('Error occured : ', error.message);
            });
    }
}

export default Fetch;
import Description from './topic-content-modules/Description';
import Comment from './topic-content-modules/Comment';
import KosyInfo from './topic-content-modules/KosyInfo';
import Answer from './topic-content-modules/Answer';
import FurtherExplanations from './topic-content-modules/FurtherExplanations';
import HintForAnswer from './topic-content-modules/HintForAnswer';
import SlopeSketch from './question-conent-modules/SlopeSketch';
import SlopeDirectionButtons from './topic-content-modules/SlopeDirectionButtons';
import DrawingSketchConnector from './helper/DrawingSketchConnector';
import {DomClasses, DomIds} from './utils/DomConstants';
import HintFloorDrain from './topic-content-modules/HintFloorDrain';
import PermanentText from './topic-content-modules/PermanentText';
import TopicHelper from './helper/TopicHelper';
import AnswerHelper from './helper/AnswerHelper';
import NumberInputfieldHelper from './helper/NumberInputfieldHelper';
import {DomUtils} from './utils/DomUtils';

(function () {
    let CONST_HIDDEN_CLASS = 'hidden';
    let CONST_ACTIVE_TOPIC_CLASS = 'active-topic';
    let ANSWER_CONNECT_JOIN = 'answer-26';
    let ANSWER_CROSS_JOIN = 'answer-27';
    let ANSWER_HORIZONTAL = 'answer-28';
    let ANSWER_VERTICAL = 'answer-29';
    let ANSWER_WITHOUT_WALLS = 'answer-68';
    let ANSWER_WITHOUT_COVERING_EDGES = 'answer-5';
    let T_S_COVERING_TYPE_ID = 17;
    let withWallGlobal = false;
    let withCoveringEdgeGlobal = false;
    let withParapetGlobal = false;
    let withSlopeDirectionGlobal = false;
    let withFloorDrainGlobal = false;
    let validStep = false;
    let oldTopicId;

    $(function () {
        const activeTopic = document.querySelector('.active-topic');
        if (activeTopic) {
            const clickedTopicId = getClickedTopicId(activeTopic.id);
            const drawingSketchConnector = new DrawingSketchConnector();
            drawingSketchConnector.slopeValuesShouldBeRendered(clickedTopicId);
            drawingSketchConnector.showHideTwoPentSlopeLine();


            const hintFloorDrain = new HintFloorDrain();
            hintFloorDrain.render(clickedTopicId - 1);
        }

        fillShapeSketch();
        clickFillInputElements();
        progressBar();
        scrollForAnswerDrawingField();
        onPopState();

        $('#answer-12').click(function () {
            madeNextStepActive();
            $('#drawing-answer-11').prop('checked', false);

        });
        $('#answer-11').click(function () {
            madeNextTopicActive();
        });

        $('.topic-step').click(function () {
            takeTopicsStep(this);
        });

        $('.topic-step').hover(
            function () {
                takeTopicsAnswers(this);
            },
            function () {
                $('.answers-style').removeClass('answers-active');
            }
        );

        $('.topic-headline').hover(function () {
            if (isClickableTopic(this)) {
                $('#' + this.id).css('cursor', 'pointer');
            }
        }, function () {
            $('#' + this.id).css('cursor', 'default');
        });

        $('.topic-headline').click(function () {
            if (isClickableTopic(this)) {
                let oldTopic = $('.active-topic')[0];
                oldTopicId = getClickedTopicId(oldTopic.id);
                validateTopic(this);
                if ($('#answer-11').length) {
                    checkDoorsWindowsCount();
                }
            }
            hideAnswerList();
            scrollForAnswerDrawingField();
        });

        if ($('#answer-11').length) {
            checkDoorsWindowsCount();
            checkDoorsWindowsCountWithChange();
            focusDoorsWindowsCount();
        }

        fillDefaultValues();
        getCheckedAnswersAndSaveInDB();
        const slopeDirectionButtons = new SlopeDirectionButtons();
        slopeDirectionButtons.render();
    });

    $('#to-next-topic').on('click', function () {
        clickNextTopic();
    });

    $('#to-previous-topic').on('click', function () {
        clickPreviousTopic();
    });

    function clickNextTopic() {
        let allTopics = $('.topic-headline');
        let activeTopic = $('.active-topic')[0];
        let activeTopicId = parseInt(getClickedTopicId(activeTopic.id));

        let lastTopic = allTopics[allTopics.length - 1];
        let lastTopicId = parseInt(getInputsElementId(lastTopic.id));

        if (activeTopicId < lastTopicId) {
            let nextTopicId = activeTopicId + 1;
            let nextTopic = $('#headline-topic-' + nextTopicId)[0];
            validateTopic(nextTopic);
        } else {
            validateTopic(activeTopic);
        }


        if ($('#answer-11').length) {
            checkDoorsWindowsCount();
        }

        if (validStep) {
            madeNextStepActive();
        }

        hideAnswerList();
        scrollForAnswerDrawingField();
    }

    function clickPreviousTopic() {
        let allTopics = $('.topic-headline');
        let activeTopic = $('.active-topic')[0];
        let activeTopicId = parseInt(getClickedTopicId(activeTopic.id));

        let firstTopic = allTopics[0];
        let firstTopicId = parseInt(getInputsElementId(firstTopic.id));

        let lastTopic = allTopics[allTopics.length - 1];
        let lastTopicId = parseInt(getInputsElementId(lastTopic.id));

        let previousTopicId = activeTopicId;
        if (activeTopicId > firstTopicId) {
            previousTopicId = activeTopicId - 1;
            let previousTopic = document.getElementById('headline-topic-' + previousTopicId);
            validateTopic(previousTopic);
            if ($('#answer-11').length) {
                checkDoorsWindowsCount();
            }
            hideAnswerList();
            scrollForAnswerDrawingField();
        }

        if (previousTopicId < lastTopicId) {
            madeNextTopicActive();
        }

        if (previousTopicId === firstTopicId) {
            madePreviousStepActive();
        }
    }

    function madeNextStepActive() {
        $('#to-next-topic').addClass('hidden');
        $('#validate-step').removeClass('hidden');
    }

    function madeNextTopicActive() {
        $('#to-next-topic').removeClass('hidden');
        $('#validate-step').addClass('hidden');
    }

    function madePreviousStepActive() {
        $('#to-previous-topic').addClass('hidden');
        $('#back-step').removeClass('hidden');
    }

    function madePreviousTopicActive() {
        $('#to-previous-topic').removeClass('hidden');
        $('#back-step').addClass('hidden');
    }

    function scrollForAnswerDrawingField() {
        if (($('#topic-answer-image').length && $('#topic-drawing-container').length) || ($('#topic-big-answer-image').length && $('#topic-drawing-container').length)) {
            // $(".main-content").css("overflow-y", "hidden");
        }
    }

    function isClickableTopic(target) {
        let activeTopic = $('.active-topic')[0];
        let activeTopicId = parseInt(getClickedTopicId(activeTopic.id));
        let clickedID = parseInt(getClickedTopicId(target.id));
        let notClickableID = activeTopicId + 2;

        return clickedID < notClickableID;
    }

    function getCheckedAnswersAndSaveInDB() {
        let checkedInputsElements = $('#topic-inputs input:checked');
        let checkedInputsIds = [];

        $.each(checkedInputsElements, function (index, checkedInputsElement) {
            checkedInputsIds[index] = getInputsElementId(checkedInputsElement['id']);
        });
        saveCheckedAnswers(checkedInputsIds);
    }

    function validateTopic(topic) {
        let activeTopicNodePos = $('.active-topic')[0].id;
        let activeTopicNodeUid = $('.active-topic')[0].getAttribute('data-topic');
        let activeTopicId = getInputsElementId(activeTopicNodeUid);
        let activeTopicPos = getInputsElementId(activeTopicNodePos);
        let clickedTopicId = getInputsElementId(topic.id);
        let data = getPostDataToValidate(activeTopicId);
        let topicUid = topic.getAttribute('data-topic');

        $.post('/topic/validate', data)
            .done(function (responseData) {
                let errors = JSON.parse(responseData);
                demarkErrorFields();
                if ((isClickedTopicAfterActiveTopic(clickedTopicId, activeTopicPos) || parseInt(clickedTopicId) === $('.topic-headline').length) && topicHasErrors(errors)) {
                    markErrorFields(errors);
                    fillErrorMessages(errors);
                    const loadingCircle = document.getElementById(DomIds.LOADING_CIRCLE);
                    loadingCircle.classList.add(DomClasses.HIDDEN);
                } else {
                    getTopicStatus(topic.id);
                    findContentOfTopic(topicUid, clickedTopicId);
                    progressBarUpdate(topic.id);
                    hideErrorMessages();
                }
            });

        if (clickedTopicId > 1) {
            madePreviousTopicActive();
        } else {
            madePreviousStepActive();
        }
    }

    function changeBrowserHistory(topicId) {
        const link = window.location.pathname + '?topicId=' + topicId;
        history.pushState(null, null, link);
    }

    function onPopState() {
        window.onpopstate = function () {
            const url = new URL(document.location);
            const id = 'progress-bar-' + url.searchParams.get('topicId');
            const barItem = document.getElementById(id);
            takeTopicsStep(barItem);
        };
    }

    function isClickedTopicAfterActiveTopic(clickedTopicId, activeTopicId) {
        return parseInt(clickedTopicId) > parseInt(activeTopicId);
    }

    function topicHasErrors(errors) {
        return Object.keys(errors).length > 0;
    }

    function getPostDataToValidate(topic) {
        // let textInputFields = $('#topic-inputs input[type="text"]');
        // let numberInputFields = $('#topic-inputs input[type="number"]');
        let radioButtons = $('#topic-inputs input:checked');
        // let inputFields = numberInputFields.add(radioButtons);
        let inputFields = getVisibleInputFieldsValues();

        let dropdownFields = $('select');

        for (let i = 0; i < radioButtons.length; i++) {
            inputFields = inputFields.concat(radioButtons[i]);
        }

        for (let i = 0; i < dropdownFields.length; i++) {
            inputFields = inputFields.concat(dropdownFields[i]);
        }

        let data = {};
        data.topicUid = topic;
        for (let c = 0; c < inputFields.length; c++) {
            if (inputFields[c].name !== '') {
                data[inputFields[c].name] = inputFields[c].value;
            }
        }

        return data;
    }

    function getVisibleInputFieldsValues() {
        let numberInputFields = $('#topic-inputs input[type="number"]');
        let textInputFields = $('#topic-inputs input[type="text"]');
        let inputFieldsValues = [];
        let count = 0;

        if (numberInputFields.length > 0) {
            for (let i = 0; i < numberInputFields.length; i++) {
                let numberInputId = numberInputFields[i].getAttribute('id');
                let numberId = getInputsElementId(numberInputId);
                if (!$('#inputfieldAndLabel-' + numberId).hasClass('hidden')) {
                    inputFieldsValues [count] = numberInputFields[i];
                    count++;
                }
            }
        }

        if (textInputFields.length > 0) {
            for (let i = 0; i < textInputFields.length; i++) {
                let textInputId = textInputFields[i].getAttribute('id');
                let testId = getInputsElementId(textInputId);
                if (!$('#inputfieldAndLabel-' + testId).hasClass('hidden')) {
                    inputFieldsValues [count] = textInputFields[i];
                    count++;
                }
            }
        }


        return inputFieldsValues;
    }

    function markErrorFields(errors) {
        for (let key in errors) {
            if (errors.hasOwnProperty(key)) {
                let errorInput = $('input[name=' + key + ']');
                if (errorInput.length !== 0) {
                    errorInput.addClass('error-input');
                }
            }
        }
    }

    function demarkErrorFields() {
        let inputFields = document.getElementsByClassName('input-field');
        let length = inputFields.length;
        for (let i = 0; i < length; i++) {
            inputFields[i].classList.remove('error-input');
        }
    }

    function fillErrorMessages(errors) {
        let kosyErrorNode = $('#kosy-error');
        kosyErrorNode.html('');
        for (let key in errors) {
            if (errors.hasOwnProperty(key)) {
                kosyErrorNode.append(errors[key] + '<br>');
                kosyErrorNode.removeClass(CONST_HIDDEN_CLASS);
                $('#kosy-image').addClass('hidden');
                $('#kosy-info').addClass('hidden');
                $('#kosy-image-error').removeClass('hidden');
            }
        }
    }

    function hideErrorMessages() {
        let kosyErrorNode = $('#kosy-error');
        kosyErrorNode.html('');
        kosyErrorNode.addClass(CONST_HIDDEN_CLASS);
    }

    function getTopicStatus(topicId) {
        let currentId = parseInt(getInputsElementId(topicId));
        let topicStatus = '';
        $('.topic-headline').each(function (index, element) {
            let topicId = parseInt(getInputsElementId(element.id));
            if (topicId < currentId) {
                topicStatus = 'answered-topic-color';
                removeTopicStatusColor(topicId);
                $('#headline-topic-' + topicId).addClass(topicStatus);
            } else if (topicId == currentId) {
                topicStatus = 'active-topic-color';
                removeTopicStatusColor(topicId);
                $('#headline-topic-' + topicId).addClass(topicStatus);
            } else if (topicId == currentId + 1) {
                topicStatus = 'inactive-topic-color next-topic-color';
                removeTopicStatusColor(topicId);
                $('#headline-topic-' + topicId).addClass(topicStatus);
            } else {
                topicStatus = 'inactive-topic-color';
                removeTopicStatusColor(topicId);
                $('#headline-topic-' + topicId).addClass(topicStatus);
            }
        });
    }

    function removeTopicStatusColor(topicId) {
        $('#headline-topic-' + topicId).removeClass('next-topic-color');
        if ($('#headline-topic-' + topicId).hasClass('answered-topic-color')) {
            $('#headline-topic-' + topicId).removeClass('answered-topic-color');
            $('#progress-bar-' + topicId).removeClass('answered-bar-color');
        } else if ($('#headline-topic-' + topicId).hasClass('inactive-topic-color')) {
            $('#headline-topic-' + topicId).removeClass('inactive-topic-color');
            $('#progress-bar-' + topicId).removeClass('inactive-bar-color');
            $('#progress-bar-' + topicId).removeClass('item-not-clickable');
        } else if ($('#headline-topic-' + topicId).hasClass('active-topic-color')) {
            $('#headline-topic-' + topicId).removeClass('active-topic-color');
            $('#progress-bar-' + topicId).removeClass('active-bar-color');
        }
    }

    function findContentOfTopic(topicUid, topicId) {
        let data = {'topicUid': topicUid};

        $.post('/topic/get-topic-content', data)
            .done(function (responseData) {
                KosyInfo.delete();
                var topicInput = $('#topic-inputs');
                topicInput.html('');
                var resultObj = JSON.parse(responseData);
                changeBrowserHistory(resultObj['topicId']);
                renderAnswerContainer(resultObj);
                clickFillInputElements();
                showHideSketchViewButtons(topicUid);
                DrawingSketchConnector.map3DHeightValues(topicUid);
                setActiveTopic(topicId);
                fillDefaultValues();
                fillCalculationResults(resultObj);
                enableDisableNextButton(resultObj);
                validStep = resultObj['validStep'];

                const furtherExplanations = new FurtherExplanations();
                furtherExplanations.hideShow(resultObj);

                const hintForAnswer = new HintForAnswer();
                hintForAnswer.renderHintForOneAnswer(resultObj);
                hintForAnswer.renderHintOnClickIfOtherAnswersShown(resultObj);
                hintForAnswer.renderHintForSummarySlope(resultObj);


                const permanentText = new PermanentText();
                permanentText.render(resultObj);

                const hintFloorDrain = new HintFloorDrain();
                hintFloorDrain.render(topicId - 1);

                if (parseInt(oldTopicId) === parseInt(topicId)) {
                    fillErrorMessages(resultObj['errors']);
                }
                resizeCanvas();
                var check3DNode = document.getElementById('check-DckGnd3d');
                if (check3DNode) {
                    check3DNode.checked = false;
                }


                if (TopicHelper.isUndergroundSlope()) {
                    const drawingSketchConnector = new DrawingSketchConnector();
                    drawingSketchConnector.setUndergroundSlopeInitValues();
                    $('#drawing-with-floor-drain-without-labels').prop('checked', false);
                    $('#drawing-without-floor-drain').prop('checked', true);
                }
                if (TopicHelper.isFloorDrain()) {
                    const drawingSketchConnector = new DrawingSketchConnector();
                    drawingSketchConnector.setFloorDrainInitValues();

                    $('#inputfield-15').keyup(function (e) {
                        changeFloorDrainPosition(e.target.id, e.target.value);
                        HintFloorDrain.hideShow();
                    });
                    $('#inputfield-16').keyup(function (e) {
                        changeFloorDrainPosition(e.target.id, e.target.value);
                        HintFloorDrain.hideShow();
                    });
                }

                if (TopicHelper.isCoveringSlope()) {
                    if ($('#answer-151').attr('checked')) {
                        $('#drawing-with-slope').prop('checked', false);
                        $('#render-slope-labels').prop('checked', false);
                    }
                    if ($('#answer-152').attr('checked')) {
                        $('#drawing-with-slope').prop('checked', true);
                        $('#render-slope-labels').prop('checked', true);
                    }
                    if ($('#answer-187').attr('checked')) {
                        $('#drawing-with-slope').prop('checked', true);
                        $('#render-slope-labels').prop('checked', true);
                    }

                    const drawingSketchConnector = new DrawingSketchConnector();
                    drawingSketchConnector.setCoveringSlopeInitValues();
                }

                if (TopicHelper.isSummarySlope()) {
                    const drawingSketchConnector = new DrawingSketchConnector();
                    drawingSketchConnector.setSummarySlopeInitValues();
                }
                DomUtils.recalculateDrawing();
                setMainConentScroll();
                $('#loading-circle').addClass('hidden');
            });
    }

    function setMainConentScroll() {
        let conentNode = document.getElementsByClassName('main-content');
        if (conentNode.length > 0) {
            conentNode[0].scrollTop = 0;
        }
    }

    function showHideSketchViewButtons(topicUid) {
        if (topicUid === 'T_A_SLOPE_SUMMARY') {
            $('#canvas-buttons-sketch').removeClass('hidden');
            $('#btn-2d-height-view').removeClass('selected-view-button');
            $('#btn-3d-height-view').addClass('selected-view-button');
            $('#viewer').removeClass('hidden');
            $('#canvas-sketch').addClass('hidden');
        } else if (document.getElementById('check-DckGnd3d')) {
            $('#canvas-buttons-sketch').addClass('hidden');
            $('#btn-2d-height-view').addClass('selected-view-button');
            $('#btn-3d-height-view').removeClass('selected-view-button');
            $('#viewer').addClass('hidden');
            $('#canvas-sketch').removeClass('hidden');
        }
    }

    function clickFillInputElements() {
        $('#topic-inputs input[type="radio"]').click(function () {
            const drawingSketchConnector = new DrawingSketchConnector();
            if (SlopeDirectionButtons.noSlopeDirectionButton(this.id)) {
                hideAnswerList();
                clickOnRadio(this);
                HintForAnswer.hideShowHintOnClickIfOtherAnswersShown(getInputsElementId(this.id));

                drawingSketchConnector.hideShowSlopeResultsInDrawing(this.id);
            }
            drawingSketchConnector.showHideTwoPentSlopeLine();
        });

        if ($('#answer-151').attr('checked')) {
            const drawingSketchConnector = new DrawingSketchConnector();
            drawingSketchConnector.hideShowSlopeResultsInDrawing(DomIds.ANSWER_WITHOUT_COVERING_SLOPE);
        }

        $('label').click(function () {
            /* IE clickin label doesn't work if input is invisible */
            let radioButton = document.querySelector('#' + this.dataset.label);
            if (radioButton) {
                radioButton.checked = true;
                hideAnswerList();
                clickOnRadio(radioButton);
                const drawingSketchConntector = new DrawingSketchConnector();
                drawingSketchConntector.showHideTwoPentSlopeLine();
            }
        });
        $(document).click(function (event) {
            const target = $(event.target);
            if (target.hasClass('notes-demand') || target.hasClass('kosy-image')) {
                $('#kosy-info').removeClass('hidden');
            } else {
                $('#kosy-info').addClass('hidden');
                hideKosyError();
            }
        });

        $('#kosy-image-error').click(function () {
            hideKosyError();
        });

        function hideKosyError() {
            $('#kosy-image-error').addClass('hidden');
            $('#kosy-error').addClass('hidden');
            if ($('#kosy-info').text().trim().length) {
                $('#kosy-image').removeClass('hidden');
            }
        }

        $('#topic-inputs input[type="checkbox"]').click(function () {
            hideAnswerList();
            saveElementInDB(this);
            deselectAllWallsAndCoveringEdges(this);
        });

        $('input[type="text"]').focusout(function () {
            hideAnswerList();
            makeAjaxCallToSaveInputValue(this);
        });

        $('input[type="number"]').focusout(function (event) {
            NumberInputfieldHelper.isInteger(event.target.value, event.target.id);
            hideAnswerList();
            makeAjaxCallToSaveInputValue(this);
        });
    }

    function hideAnswerList() {
        $('#answers-show').removeClass('hidden');
        $('#answers-hide').addClass('hidden');
        $('#answers-table').addClass('list-no-visible');
    }

    function delay(callback, ms) {
        let timer = 0;
        return function () {
            let context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }

    function clickOnRadio(radio) {
        if (radio.name === 'Q_DOOR_WINDOW') {
            setDrainageVisibility(radio);
        }

        if (radio.name === 'Q_COVERING_LAYING') {
            setLayingAlternativeVisibility(radio);
        }

        if (radio.name === 'Q_FLOOR_DRAIN') {
            setRequiredVisibility(radio);
        }

        if (radio.classList.contains('withComment')) {
            $('.topic-comment').removeClass(DomClasses.INVISIBLE);
        } else {
            $('.topic-comment').addClass(DomClasses.INVISIBLE);
        }

        saveElementInDB(radio);
        deselectAllWallsAndCoveringEdges(radio);
    }

    function setRequiredVisibility(answer) {
        if (answer.id === DomIds.ANSWER_WITH_FLOOR_DRAIN) {
            $('#requiered-field').removeClass('hidden');
        } else {
            $('#requiered-field').addClass('hidden');
        }
    }

    function setDrainageVisibility(doorButton) {
        if (doorButton.id == 'answer-11') {
            $('div[data-topic="T_A_DRAINAGE"]').removeClass('no-visibility');
            $('#drainage-step').removeClass('no-drain');
            madeNextTopicActive();
        } else {
            let data = {'questioinUid': 'Q_DRAINAGE'};
            $.post('/configuration-question-answer/delete-all-answers-of-question', data);
            $('div[data-topic="T_A_DRAINAGE"]').addClass('no-visibility');
            $('#drainage-step').addClass('no-drain');
            madeNextStepActive();
        }
    }

    function setLayingAlternativeVisibility(coveringButton) {
        if (coveringButton.id === 'answer-66' && $('#showLayingAlternatives').hasClass('withLayingAlt')) {
            $('div[data-topic="T_S_LAYING_ALTERNATIVE"]').removeClass('no-visibility');
            $('#laying-alt-step').removeClass('no-alternative');
            madeNextTopicActive();
        } else {
            let data = {'questioinUid': 'Q_LAYING_ALTERNATIVE'};
            $.post('/configuration-question-answer/delete-all-answers-of-question', data);
            $('div[data-topic="T_S_LAYING_ALTERNATIVE"]').addClass('no-visibility');
            $('#laying-alt-step').addClass('no-alternative');
            madeNextStepActive();
        }
    }

    function setActiveTopic(topicId) {
        let oldActiveTopics = $('.' + CONST_ACTIVE_TOPIC_CLASS);
        let clickedTopic = $('#headline-topic-' + topicId);
        oldActiveTopics.each(function (index, element) {
            $('#' + element.id).removeClass(CONST_ACTIVE_TOPIC_CLASS);
        });
        clickedTopic.addClass(CONST_ACTIVE_TOPIC_CLASS);
    }

    function renderAnswerContainer(ajaxResult) {
        let inputfields = [];
        let questions = [];
        let checkedAnswers = [];
        let hasPlateDrawing = false;
        let showImageFromSystemQuestionAnswer = '';
        let showAnswerInFullWidth = false;
        let showKosy = false;

        $('.topic-comment').html('');

        const kosyInfo = new KosyInfo();

        const ordered = Object.keys(ajaxResult).sort().reduce(
            (obj, key) => {
                obj[key] = ajaxResult[key];
                return obj;
            },
            {}
        );

        $.each(ordered, function (index, value) {
            if (index === 'questions') {
                questions = value;
            } else if (index === 'inputfieldsgroup') {
                inputfields = value;
            } else if (index === 'checkedAnswers') {
                checkedAnswers = value;
            } else if (index === 'topicDescription') {
                const description = new Description();
                description.render(value, ajaxResult);
            } else if (index === 'topicComment') {
                const comment = new Comment();
                comment.render(value);
            } else if (index === 'hasPlateDrawing') {
                hasPlateDrawing = value;
            } else if (index === 'showImageFromSystemQuestionAnswer') {
                showImageFromSystemQuestionAnswer = value;
            } else if (index === 'showAnswerInFullWidth') {
                showAnswerInFullWidth = value;
            } else if (index === 'kosyInfos') {
                showKosy = kosyInfo.render(value);
            } else if (index === 'generalInfos') {
                showKosy = kosyInfo.renderGeneralInfos(value);
            }


            $('#kosy-image-error').addClass('hidden');
        });

        kosyInfo.hide(ordered);

        $.each(checkedAnswers, function (key, value) {
            if (key == 'Q_DOOR_WINDOW' && value == 12) {
                $('#drainage-step').addClass('no-drain');
            }
        });

        if (ajaxResult.topicId === T_S_COVERING_TYPE_ID) {
            getInputFields(inputfields, checkedAnswers);
        }

        getAnswers(questions, checkedAnswers);

        if (ajaxResult.topicId !== T_S_COVERING_TYPE_ID) {
            getInputFields(inputfields, checkedAnswers);
        }
        checkRequiredFields(inputfields);
        saveCheckedAnswers(checkedAnswers);
        fillDrawingContainer(hasPlateDrawing, showImageFromSystemQuestionAnswer, showAnswerInFullWidth);
        $('#topic-inputs').append('<div id="shape-sketch"></div>');

        const slopeSketch = new SlopeSketch(ajaxResult.topicId);
        slopeSketch.render();
    }

    function saveCheckedAnswers(checkedAnswers) {
        $.each(checkedAnswers, function (index, checkedAnswer) {
            let data = {'answerId': checkedAnswer, 'buttonType': 'radio'};
            $.post('/configuration-question-answer/save', data)
                .done(function (responseData) {
                    let response = JSON.parse(responseData);
                    enableDisableNextButton(response);
                });
        });
    }

    function checkRequiredFields(inputfields) {
        let requiredVisibility = 0;
        $.each(inputfields, function (index, value) {
            $.each(value, function (item, object) {
                if (item === 'inputfields') {
                    $.each(object, function (i, objectfeature) {
                        if (objectfeature.required === 1 && !$('#inputfieldAndLabel-' + objectfeature.id).hasClass('hidden')) {
                            requiredVisibility = 1;
                            $('#requiered-field').removeClass('hidden');
                        }
                    });
                    if (requiredVisibility === 0) {
                        $('#requiered-field').addClass('hidden');
                    }
                }
            });

        });
    }

    function fillDrawingContainer(hasPlateDrawing, showImageFromSystemQuestionAnswer, showAnswerInFullWidth) {
        if (hasPlateDrawing) {
            $('#topic-drawing-container').removeClass('visibility-none');
            $('#topic-drawing-container').addClass('col-md-8');
            $('#answer-container').removeClass('col-md-12');
            $('#answer-container').addClass('col-md-4');
            $('#topic-big-answer-image').addClass('hidden');
            $('#topic-big-answer-image').removeClass('col-md-8');
            $('#topic-answer-image').removeClass('col-md-12');
            $('#topic-answer-image').addClass('col-md-4');
        } else if (showImageFromSystemQuestionAnswer !== '') {
            $('#topic-big-answer-image').removeClass('hidden');
            $('#topic-big-answer-image').addClass('col-md-8');
            $('#topic-drawing-container').addClass('visibility-none');
            $('#topic-drawing-container').removeClass('col-md-8');
            $('#answer-container').removeClass('col-md-12');
            $('#answer-container').addClass('col-md-4');
            if ($('#topic-big-answer-image img')) {
                $('#topic-big-answer-image img')[0].src = showImageFromSystemQuestionAnswer;
            }
        } else if (showAnswerInFullWidth) {
            $('#topic-answer-image').removeClass('hidden');
            $('#topic-answer-image').addClass('col-md-12');
            $('#topic-drawing-container').addClass('visibility-none');
            $('#topic-drawing-container').removeClass('col-md-8');
        } else {
            $('#topic-drawing-container').addClass('visibility-none');
            $('#topic-drawing-container').removeClass('col-md-8');
            $('#answer-container').removeClass('col-md-4');
            $('#answer-container').addClass('col-md-12');
            $('#topic-big-answer-image').addClass('hidden');
            $('#topic-big-answer-image').removeClass('col-md-8');
        }
    }

    function getAnswers(questions, checkedAnswers) {
        let answers = [];
        let headline = '';
        let annotation = '';
        let position = '';
        let order = 'row';
        let rowClass = '';
        let row = 0;
        let rowId = 'topic-inputs';
        let door = '';
        let window = '';

        $.each(questions, function (index, questionContent) {
            headline = '';
            answers = questionContent['answers'];
            let countOfVisibleAnswers = 0;

            if ('headline' in questionContent) {
                headline = questionContent['headline'];
            }

            if ('annotation' in questionContent) {
                annotation = questionContent['annotation'];
            }

            if ('equipment' in questionContent) {
                door = questionContent['equipment']['door'];
                window = questionContent['equipment']['window'];
            }

            if ('position' in questionContent) {
                position = questionContent['position'];
                rowClass = questionContent['rowCount'].replace(' ', '-');
            }

            if ('door' in questionContent) {
                door = questionContent['door'];
            }

            if ('window' in questionContent) {
                window = questionContent['window'];
            }

            if (position === 'left') {
                if (rowId === 'topic-inputs') {
                    $('#topic-inputs').append(
                        '<div class="row dropdown-heading-line"><div class="col-md-5"></div><div class="col-md-3">Türen</div><div class="col-md-3">Fenster</div> </div>'
                    );
                }

                rowId = 'row-' + row;

                $('#topic-inputs').append(
                    '<div class="row dropdown-row ' + rowClass + '" id="' + rowId + '"><div class="col-md-5">' + questionContent['rowCount'] + '</div></div>'
                );
                order = 'col-md-3';
                row++;
            }

            if ('visibility' in questionContent) {
                $('.' + rowClass).addClass('with-visibility');
                $('.dropdown-heading-line').addClass('with-visibility');

                for (let i = 0; i <= questionContent['visibility'].length - 1; i++) {
                    let answerNumber = questionContent['visibility'][i];
                    $('.' + rowClass).addClass('visible-for-' + answerNumber);
                    $('.dropdown-heading-line').addClass('visible-for-' + answerNumber);
                }
            }

            $('#' + rowId)
                .append(
                    '<div class="answer-headline" data-headline="' + index + '-headline">' + headline + '</div>'
                    + '<div id="' + index + '"'
                    + ' class="question-container ' + order + '">'
                    + '</div>'
                    + '<span id="error-' + index + '" class="error-message ' + CONST_HIDDEN_CLASS + '"></span>'
                );

            let selectedAnswers = [];
            $.each(answers, function (answer, val) {
                let answerType = val['answerStyle'];
                let answersCount = Object.keys(answers).length;

                let columnSize = gridViewForAnswers(answersCount, answerType);

                selectedAnswers.push(answersPresentation(columnSize, index, val, checkedAnswers));


                if (val['currentVisibility'] === true) {
                    countOfVisibleAnswers++;
                }
            });

            selectedAnswers.forEach((answer) => {
                if (answer) {
                    answer.setDropDownListValue();
                }

            });

            if ($('#answer-11').length) {
                checkDoorsWindowsCount();
                checkDoorsWindowsCountWithChange();
                focusDoorsWindowsCount();
            }

            if (index === 'Q_COVERING_TYPE') {
                $('#' + index)
                    .append(
                        '<div class="col-md-4 columnLayout">'
                        + '<div class="notes-demand">'
                        + annotation
                        + '</div>'
                        + '</div>'
                    );
            }

            if (countOfVisibleAnswers === 1) {
                $('.' + index).each(function (index, element) {
                    let currentAnswer = element.getAttribute('data-label');
                    if (!$('label[data-label="' + currentAnswer + '"]').hasClass('hidden')) {
                        let radioButton = document.querySelector('#' + element.dataset.label);
                        if (radioButton) {
                            radioButton.checked = true;
                            let singleAnswerId = getInputsElementId(radioButton.id);
                            if (checkedAnswers.length === 0) {
                                checkedAnswers[0] = singleAnswerId;
                            } else {
                                checkedAnswers[checkedAnswers.length] = singleAnswerId;
                            }
                            clickOnRadio(radioButton);
                        }
                    }
                });
            }
        });
        if ($('#answer-11').length) {
            checkDoorsWindowsCount();
        }
    }

    function gridViewForAnswers(answersCount, answerType) {
        let answersColumn = '';

        if (answerType === 'image-answer') {
            answersColumn = 4;
        } else if (answerType === 'shape-answer') {
            answersColumn = 3;
        } else if (answerType === 'label-image-answer') {
            answersColumn = 12;
        } else {
            let col = getInputsElementId(answerType);
            answersColumn = 12 / col;
        }

        return answersColumn;
    }

    function answersPresentation(columnSize, question, val, checkedAnswers) {
        let selectedAnswer;
        if (val['id'] == 7 || val['id'] == 8) {
            columnSize = 12;
        }

        let answerStatus = ' answer-label';
        let checked = '';
        let title = '';
        let hidden = '';

        if (!$('#requiered-field').hasClass('hidden')) {
            $('#requiered-field').addClass('hidden');
        }

        if ('title' in val) {
            title = val['title'];
        }

        let showLayingAlternatives = false;

        if (val['uid'] === 'A_SITUATED_INSTALLATION') {
            if ('showLayingAlternatives' in val) {
                showLayingAlternatives = val['showLayingAlternatives'];
                if (showLayingAlternatives === false) {
                    deleteLayingAlternatives();
                } else {
                    $('#showLayingAlternatives').addClass('withLayingAlt');
                }
            } else {
                $('#showLayingAlternatives').addClass('withLayingAlt');
            }
        }

        $.each(checkedAnswers, function (index, answerId) {
            if (val['id'] == answerId) {
                checked = 'checked';
                answerStatus = ' answered-label-active';

                if (answerId === 66) {
                    if ($('#showLayingAlternatives').length && $('#showLayingAlternatives').hasClass('withLayingAlt')) {
                        $('div[data-topic="T_S_LAYING_ALTERNATIVE"]').removeClass('no-visibility');
                    }
                }

                if ('commentVisibility' in val) {
                    if (val['commentVisibility'] === true) {
                        $('.topic-comment').removeClass(DomClasses.INVISIBLE);
                    } else {
                        $('.topic-comment').addClass(DomClasses.INVISIBLE);
                    }
                }
            }
        });


        let image = '';
        let caption = '<span class="answer-name">' + val['name'] + '</span>';
        let answerStyle = val['answerStyle'];
        let columnHidden = '';
        let imageDescription = '';
        let commentVisibility = '';

        if (val['currentVisibility'] === false) {
            hidden = ' hidden blocked';
            columnHidden = ' hidden';
        }

        if (val['imageDescription']) {
            imageDescription = val['imageDescription'];
        }

        if (answerStyle === 'image-answer') {
            image = '<div><img id="' + val['uid'] + '" src="' + val['imagePath'] + '" class="img-responsive answer-image"></div>';
        } else if (answerStyle === 'shape-answer') {
            answerStatus = '';

            if (val[checked] === true) {
                image = '<div><img src="' + val['imagePathActive'] + '" class="img-responsive answer-shape-active" id="answer-' + val['id'] + '-active">' +
                    '<img src="' + val['imagePathInactive'] + '" class="img-responsive answer-shape-inactive hidden" id="answer-' + val['id'] + '-inactive"></div>';
            } else {
                image = '<div><img src="' + val['imagePathInactive'] + '" class="img-responsive answer-shape-inactive" id="answer-' + val['id'] + '-inactive">' +
                    '<img src="' + val['imagePathActive'] + '" class="img-responsive answer-shape-active hidden" id="answer-' + val['id'] + '-active"></div>';
            }
            caption = '';
        }

        if ('commentVisibility' in val) {
            if (val['commentVisibility'] === true) {
                commentVisibility = 'withComment';
            } else {
                commentVisibility = 'withouComment';
            }
        }

        const answer = new Answer(question, val, answerStyle, checked);
        answer.render(checkDoorsWindowsCountWithChange, columnSize, columnHidden, commentVisibility, title, answerStatus, hidden, image, caption, imageDescription);

        if (val.checked) {
            selectedAnswer = answer;
        }

        if ('visibility' in val) {
            activateVisibilityForAnswer(val['visibility'], val['id']);
        }

        headlinesVisibility();
        titleVisibility();
        let answerNode = document.getElementById('answer-' + val['id']);
        if (val['id'] === 151 && answerNode.checked) {
            answerNode.click();
        }

        return selectedAnswer;
    }

    function deleteLayingAlternatives() {
        $('#showLayingAlternatives').removeClass('withLayingAlt');
        $('div[data-topic="T_S_LAYING_ALTERNATIVE"]').addClass('no-visibility');
    }

    function activateVisibilityForAnswer(visibility, id) {
        $('label[data-label="answer-' + id + '"]').addClass('hidden');
        $.each(visibility, function (index, answerId) {
            $('label[data-label="answer-' + id + '"]').addClass('visible-for-' + answerId);
            $('label[data-label="answer-' + id + '"]').addClass('with-visibility');
            if ($('#answer-' + answerId).attr('checked')) {
                $('label[data-label="answer-' + id + '"]').removeClass('hidden');
            }
        });
    }

    function getInputFields(inputFieldsGroup, checkedAnswers) {
        let inputfields = [];
        let headline = '';
        let row = 0;
        let door = '';
        let window = '';
        let direction = '';

        $.each(inputFieldsGroup, function (index, inputfieldsContent) {
            inputfields = inputfieldsContent['inputfields'];
            let rowId = index;
            let columnSize = '';

            if ('headline' in inputfieldsContent) {
                headline = inputfieldsContent['headline'];
            }

            if ('direction' in inputfieldsContent) {
                direction = inputfieldsContent['direction'];
            }

            if ('equipment' in inputfieldsContent) {
                door = inputfieldsContent['equipment']['door'];
                window = inputfieldsContent['equipment']['window'];
            }

            $('#topic-inputs')
                .append(
                    '<div class="answer-headline" data-headline="' + index + '-headline">' + headline + '</div>'
                    + '<div id ="' + index + '"'
                    + ' class="inputfield-container ' + direction + '">'
                    + '</div>'
                    + '<span id="error-' + index + '" class="error-message ' + CONST_HIDDEN_CLASS + '"></span>'
                );

            $.each(inputfields, function (inputfield, inputfieldValue) {
                let position = '';
                if (inputfieldValue['position']) {
                    position = inputfieldValue['position'];
                }

                if (position === 'left') {
                    rowId = 'inputifieldRow-' + row;
                    $('#topic-inputs').append(
                        '<div class="row" id="' + rowId + '"><div class="col-md-6" id="label-' + rowId + '">' + inputfieldValue['rowName'] + '</div> </div>'
                    );

                    let visibility = [];
                    if (inputfieldValue['visibility']) {
                        visibility = inputfieldValue['visibility'];
                        $('.inputfields-heading-line').addClass('with-visibility');
                        $('#' + rowId).addClass('with-visibility');
                    }

                    for (let i = 0; i <= visibility.length - 1; i++) {
                        let answerNumber = visibility[i];
                        $('.inputfields-heading-line').addClass('visible-for-' + answerNumber);
                        $('#' + rowId).addClass('visible-for-' + answerNumber);
                    }

                    columnSize = 'col-md-3 ';
                    row++;
                } else if (position === 'left' || index === 'IG_COVERING_FINDER') {
                    columnSize = 'col-md-3 ';
                }

                inpufieldsPresentation(rowId, columnSize, inputfieldValue, checkedAnswers);

                if (inputfieldValue['image']) {
                    let imageClass = '';
                    if (index === 'IG_CONSTRUCTION_HEIGHT') {
                        imageClass = 'construction-image';
                    }
                    $('#topic-inputs').append(
                        '<div><img src="' + inputfieldValue['image'] + '" class="img-responsive ' + imageClass + '"></div>'
                    );
                }
            });

            if (index === 'IG_CONSTRUCTION_HEIGHT_SLOPE' || index === 'IG_SLOPE_COVERING') {
                const slopeDirectionButtons = new SlopeDirectionButtons();
                slopeDirectionButtons.render();
            }
        });

    }

    function inpufieldsPresentation(inputfieldsGroup, columnSize, inputfieldValue, checkedAnswers) {

        let labelClass = 'input-field-label';
        let type = 'type="' + inputfieldValue['type'] + '" ';
        let id = 'id="inputfield-' + inputfieldValue['id'] + '" ';
        let name = 'name="' + inputfieldValue['uid'] + '"';
        let value = 'value="' + inputfieldValue['value'] + '"';
        let className = 'class="' + inputfieldValue['class'] + ' input-field"';
        let readonly = inputfieldValue['readonly'];
        let labelInputfield = '';
        let required = '';
        let hidden = CONST_HIDDEN_CLASS;


        if (inputfieldValue['required'] === 1) {
            required = '<span class="star-required">*</span>';
        }

        if (inputfieldValue['uid'] === 'I_DOOR_WIDTH' || inputfieldValue['uid'] === 'I_WINDOW_WIDTH' || inputfieldValue['uid'] === 'I_DOOR_REVEAL_DEPTH' || inputfieldValue['uid'] === 'I_WINDOW_REVEAL_DEPTH') {
            labelInputfield = '<div><span id="error-I_' + inputfieldValue['name'] + '" class="error-message ' + CONST_HIDDEN_CLASS + '"></span></div>';
        } else {
            labelInputfield = '<div class="row"><label for="inputfield-' + inputfieldValue['id'] + '" class="' + labelClass + '">'
                + inputfieldValue['name'] + '</label>' +
                '<span id="error-I_' + inputfieldValue['name'] + '" class="error-message ' + CONST_HIDDEN_CLASS + '"></span>' +
                '</div>';
        }

        let min = '';
        let max = '';
        let unit = '';

        if (inputfieldValue.hasOwnProperty('unit')) {
            unit = '<span>' + inputfieldValue['unit'] + '</span>';
        }

        if (inputfieldValue['min'] !== undefined) {
            min = 'min="' + inputfieldValue['min'] + '"';
        }
        if (inputfieldValue['max'] !== undefined) {
            max = 'max="' + inputfieldValue['max'] + '"';
        }

        $('#' + inputfieldsGroup)
            .append(
                '<div id="inputfieldAndLabel-' + inputfieldValue['id'] + '" class="' + columnSize + inputfieldsGroup + '">'
                + labelInputfield
                + '<div class="row">'
                + '<input ' + type + id + name + min + max + value + className + readonly + '/>' + unit + required + '</div>'
                + '</div>'
            );

        if ('visibility' in inputfieldValue) {
            activateVisibilityForInputfield(inputfieldValue['visibility'], inputfieldValue['id'], checkedAnswers);
        }
        headlinesVisibility();
    }

    function activateVisibilityForInputfield(visibility, id, checkedAnswers) {
        $.each(visibility, function (index, answerId) {
            $('#inputfieldAndLabel-' + id).addClass('visible-for-' + answerId);
            $('#inputfieldAndLabel-' + id).addClass('with-visibility');
        });
        $('.with-visibility').addClass('hidden');
        $.each(checkedAnswers, function (index, checkedAnswer) {
            $('.visible-for-' + checkedAnswer).each(function () {
                if (!$(this).hasClass('blocked')) {
                    $(this).removeClass('hidden');
                }
            });
        });
    }

    function saveElementInDB(input) {
        let answerId = getInputsElementId(input.id);
        let checked = $('#answer-' + answerId).prop('checked');
        let headlineName = input.name + '-headline';

        let data = {'answerId': answerId, 'buttonType': 'radio'};

        if (AnswerHelper.shouldVisibilityOfInputFieldsBeChecked(input.id)) {
            $('.with-visibility').addClass('hidden');
        }

        //
        if ($('.with-visibility').is('label[data-label="answer-' + answerId + '"]')) {
            $('.' + input.name).each(function () {
                if (!$(this).hasClass('blocked')) {
                    $(this).removeClass('hidden');
                }
            });

            if (answerId == 22 || answerId == 23) {
                if ($('#answer-19').prop('checked')) {
                    $('label[data-label="answer-20"]').removeClass('hidden');
                    $('label[data-label="answer-21"]').removeClass('hidden');
                }
            }
        }

        if (checked) {
            if (input.name === 'Q_SHAPE') {
                $('.answer-shape-active').addClass('hidden');
                $('.answer-shape-inactive').removeClass('hidden');

                $('#' + input.id + '-active').removeClass('hidden');
                $('#' + input.id + '-inactive').addClass('hidden');
            } else {
                $('.' + input.name).removeClass('answered-label-active');
                $('.' + input.name).addClass('answer-label');

                $('label[data-label="answer-' + answerId + '"]').removeClass('answer-label');
                $('label[data-label="answer-' + answerId + '"]').addClass('answered-label-active');
            }

            $('.visible-for-' + answerId).each(function () {
                if (!$(this).hasClass('blocked')) {
                    $(this).removeClass('hidden');
                }
            });

            $('label[data-headline="' + headlineName + '-headline"]').removeClass('hidden');

            $('.with-visibility').each(function (index, answer) {
                let dataId = answer.id;
                if (dataId.indexOf('inputfieldAndLabel-') != -1) {
                    let classList = $('#' + dataId).attr('class').split(/\s+/);
                    let visible = 0;
                    $.each(classList, function (index, item) {
                        if (item.indexOf('visible-for-') != -1) {
                            let answerId = getInputsElementId(item);
                            if ($('#answer-' + answerId).prop('checked')) {
                                visible = visible + 1;
                            }
                        }
                    });

                    if (visible == 0) {
                        let inputfieldId = getInputsElementId(dataId);
                        let data = {'inputfieldId': inputfieldId};
                        $.post('/configuration-input/delete', data);
                    } else {
                        let inputfieldId = getInputsElementId(dataId);
                        let inputfieldValue = $('#inputfield-' + inputfieldId).attr('value');
                        let data = {'inputId': inputfieldId, 'value': inputfieldValue};
                        $.post('/configuration-input/save', data);
                    }
                }

                let dataValue = answer.getAttribute('data-label');
                if ($('label[data-label="' + dataValue + '"]').hasClass('hidden')) {
                    $('#' + dataValue).prop('checked', false);
                    $('label[data-label="' + dataValue + '"]').removeClass('answered-label-active');
                    $('label[data-label="' + dataValue + '"]').addClass('answer-label');
                    let answerId = getInputsElementId(dataValue);
                    let data = {'answerId': answerId};
                    $.post('/configuration-question-answer/delete', data);
                }
            });
            $.post('/configuration-question-answer/save', data)
                .done(function (responseData) {
                    redrawPlatesDrawing(input);
                    let response = JSON.parse(responseData);
                    enableDisableNextButton(response);
                    if (isShapeButton(input.id)) {
                        fillShapeSketch(input.id);
                    }
                });
        }

        headlinesVisibility();
        titleVisibility();
    }

    function isShapeButton(id) {
        return id === 'answer-1'
            || id === 'answer-2'
            || id === 'answer-3'
            || id === 'answer-4'
            || id === 'answer-191'
            || id === 'answer-192'
            || id === 'answer-193'
            || id === 'answer-194';
    }

    function headlinesVisibility() {
        $('.answer-headline').each(function (index, answer) {
            let headlineValue = answer.getAttribute('data-headline');
            let dataValueArray = headlineValue.split('-');
            let className = dataValueArray[0];
            let visibility = 0;

            $('.' + className).each(function () {
                if (!$(this).hasClass('hidden')) {
                    visibility++;
                }
            });

            if (visibility === 0) {
                $('div[data-headline="' + headlineValue + '"]').addClass('hidden');
            } else {
                $('div[data-headline="' + headlineValue + '"]').removeClass('hidden');
            }
        });
    }

    function titleVisibility() {
        $('.answer-title').each(function (index, answer) {
            let titleValue = answer.getAttribute('data-answertitle');
            if (titleValue != '') {
                if ($('.' + titleValue).hasClass('hidden')) {
                    $('div[data-answertitle="' + titleValue + '"]').addClass('hidden');
                } else {
                    $('div[data-answertitle="' + titleValue + '"]').removeClass('hidden');
                }
            }
        });
    }

    function redrawPlatesDrawing(clickedInput) {
        if (isRedrawAnswer(clickedInput.id)) {
            let data = {'answer': getInputsElementId(clickedInput.id)};
            $.post('/topic/redraw-plates', data)
                .done(function (responseData) {
                    fillCalculationResults(JSON.parse(responseData));
                    $('#recalculate').click();
                    showPlatesView();
                });
        }
    }

    function deselectAllWallsAndCoveringEdges(clickedInput) {
        switch (clickedInput.id) {
            case ANSWER_WITHOUT_WALLS:
                $.post('/edge/deselect-all-walls');
                $('#drawing-without-wall').prop('checked', false);
                $('#calculateHeightsForRectangleing-wall-0').prop('checked', false);
                $('#drawing-wall-1').prop('checked', false);
                $('#drawing-wall-2').prop('checked', false);
                $('#drawing-wall-3').prop('checked', false);
                break;
            case ANSWER_WITHOUT_COVERING_EDGES:
                $('#drawing-without-covering-edges').prop('checked', false);
                $('#drawing-covering-edges-0').prop('checked', false);
                $('#drawing-covering-edges-1').prop('checked', false);
                $('#drawing-covering-edges-2').prop('checked', false);
                $('#drawing-covering-edges-3').prop('checked', false);
                $.post('/edge/deselect-all-covering-edges');
                break;
        }
        $('#recalculate').click();
    }

    function isRedrawAnswer(clickedAnswerID) {
        return clickedAnswerID === ANSWER_CONNECT_JOIN
            || clickedAnswerID === ANSWER_CROSS_JOIN
            || clickedAnswerID === ANSWER_HORIZONTAL
            || clickedAnswerID === ANSWER_VERTICAL;
    }

    function makeAjaxCallToSaveInputValue(input) {
        let inputId = getInputsElementId(input.id);
        let data = {'inputId': inputId, 'value': input.value};
        let activeTopicNodeUid = $('.active-topic')[0].getAttribute('data-topic');
        let activeTopicId = getInputsElementId(activeTopicNodeUid);
        // let topicData = getPostDataToValidate(activeTopicId);
        let topicData = {};
        topicData['topicUid'] = activeTopicId;
        topicData[input.name] = input.value;
        topicData['inputfield'] = 1;

        $.post('/configuration-input/save', data)
            .done(function () {
                $.post('/topic/validate', topicData)
                    .done(function (responseData) {
                        // let errors = JSON.parse(responseData);
                        let errors = JSON.parse(responseData);
                        let nextButtonEnabled = {'validStep': validStep};
                        demarkErrorFields();
                        if (topicHasErrors(errors)) {
                            markErrorFields(errors);
                            fillErrorMessages(errors);
                            nextButtonEnabled = {'validStep': false};
                            const loadingCircle = document.getElementById(DomIds.LOADING_CIRCLE);
                            loadingCircle.classList.add(DomClasses.HIDDEN);
                        } else {
                            hideErrorMessages();
                        }
                        enableDisableNextButton(nextButtonEnabled);
                    });
            });


    }

    function getClickedTopicId(idString) {
        return idString.split('-')[2];
    }

    function getInputsElementId(idString) {
        if (idString) {
            let splttedNodeId = idString.split('-');
            let lastPosition = splttedNodeId.length - 1;

            return splttedNodeId[lastPosition];
        }
    }

    function progressBarUpdate(topicId) {
        let id = getInputsElementId(topicId);
        $('.topic-step').each(function (index, element) {
            let elementId = parseInt(getInputsElementId(element.id));
            removeProgressBarStatusColor(elementId);
            if (elementId < id) {
                $('#progress-bar-' + elementId).addClass('answered-bar-color');
            } else if (elementId == id) {
                $('#progress-bar-' + elementId).addClass('active-bar-color');
            } else {
                $('#progress-bar-' + elementId).addClass('inactive-bar-color item-not-clickable');
            }
        });
    }

    function removeProgressBarStatusColor(elementId) {
        if ($('#progress-bar-' + elementId).hasClass('answered-bar-color')) {
            $('#progress-bar-' + elementId).removeClass('answered-bar-color');
        } else if ($('#progress-bar-' + elementId).hasClass('active-bar-color')) {
            $('#progress-bar-' + elementId).removeClass('active-bar-color');
        } else {
            $('#progress-bar-' + elementId).removeClass('inactive-bar-color item-not-clickable');
        }
    }

    function renderAnswers(value, answers) {
        let className = 'answers';
        answers.append('<input type="number" class="' + className + '"');
    }

    function progressBar() {
        let sizeOfTopic = 100 / (parseInt($('#count-topics').text()) + 2) - 0.2;
        $('.topic-step').css('width', sizeOfTopic + '%');
        $('.navi-buttons').css('width', sizeOfTopic * 2 + '%');

        // $('.answered-bar-color').css('cursor', 'pointer');

        let answeredSteps = $('.answered-bar-color');
        answeredSteps.each(function (key, topic) {
            // let stepName = topic.attr('data-bar');
            if ((topic.id == 'progress-bar-6' && $('#drainage-step').hasClass('no-drain')) || (topic.id == 'progress-bar-8' && $('#laying-alt-step').hasClass('no-alternative'))) {
                $('#' + topic.id).css('pointer-events', 'none');
                $('#' + topic.id).css('cursor', 'not-allowed');
            } else {
                $('#' + topic.id).css('cursor', 'pointer');
            }
        });
    }

    function takeTopicsStep(baritem) {
        let topicUid = $('#' + baritem.id).data('bar');

        let id = baritem.id;
        // removeTopicStatusColor(idItem);
        progressBarUpdate(id);

        let data = {'topicUid': topicUid};
        $.post('/step/get-step-for-topic', data);
    }

    function takeTopicsAnswers(baritem) {
        let topicUid = $('#' + baritem.id).data('bar');
        $('p[data-answer="' + topicUid + '"]').addClass('answers-active');
    }

    function fillDefaultValues() {
        fillInputFields();
        fillShapeAnswers();
        fillWallAnswers();
        fillCoveringEdgeAnswers();
        fillParapetEdgeAnswers();
        fillDoorWindowValues();
        fillFloorDrainValues();
        addEventListeners();
        fillShapeSketch();
    }

    function fillCalculationResults(resultObj) {
        let key = 'calculation-results';
        if (key in resultObj && !Array.isArray(resultObj[key])) {
            $('#calc-results-plates').val(resultObj[key]['platesJson']);
            $('#calc-results-substructure').val(resultObj[key]['mountingJson']);
            $('#drawing-inputfield-7').val(resultObj[key]['deckData']['a1']);
            $('#drawing-inputfield-8').val(resultObj[key]['deckData']['a2']);
            $('#drawing-inputfield-9').val(resultObj[key]['deckData']['a3']);
            $('#drawing-inputfield-10').val(resultObj[key]['deckData']['b1']);
            $('#drawing-inputfield-11').val(resultObj[key]['deckData']['b2']);
        }
    }

    function enableDisableNextButton(resultObj) {
        if (resultObj['validStep'] && isLastTopic()) {
            madeNextStepActive();
        }
    }

    function isLastTopic() {
        let allTopics = $('.topic-headline');
        let activeTopic = $('.active-topic')[0];
        let activeTopicId = parseInt(getClickedTopicId(activeTopic.id));

        let lastTopic = allTopics[allTopics.length - 1];
        if (lastTopic.className.indexOf('no-visibility') > -1) {
            lastTopic = allTopics[allTopics.length - 2];
        }
        let lastTopicId = parseInt(getInputsElementId(lastTopic.id));

        return activeTopicId === lastTopicId;
    }

    function fillInputFields() {
        let inputfields = $('.dimension-input-standard');
        inputfields.each(function (index, inputfield) {
            $('#drawing-' + inputfield.id).val(inputfield.value);
        });
    }

    function fillShapeAnswers() {

        if ($('#answer-1').length) {
            $('#drawing-answer-1').prop('checked', $('#answer-1').is(':checked'));
        }
        if ($('#answer-2').length) {
            $('#drawing-answer-2').prop('checked', $('#answer-2').is(':checked'));
        }
        if ($('#answer-3').length) {
            $('#drawing-answer-3').prop('checked', $('#answer-3').is(':checked'));
        }
        if ($('#answer-191').length) {
            $('#drawing-answer-191').prop('checked', $('#answer-191').is(':checked'));
        }
        if ($('#answer-192').length) {
            $('#drawing-answer-192').prop('checked', $('#answer-192').is(':checked'));
        }
        if ($('#answer-193').length) {
            $('#drawing-answer-193').prop('checked', $('#answer-193').is(':checked'));
        }
        if ($('#answer-4').length) {
            $('#drawing-answer-4').prop('checked', $('#answer-4').is(':checked'));
        }
        if ($('#answer-194').length) {
            $('#drawing-answer-194').prop('checked', $('#answer-194').is(':checked'));
        }
        if ($('#' + ANSWER_HORIZONTAL).length) {
            $('#radio-horizontal').prop('checked', $('#' + ANSWER_HORIZONTAL).is(':checked'));
        }
        if ($('#' + ANSWER_VERTICAL).length) {
            $('#radio-vertical').prop('checked', $('#' + ANSWER_VERTICAL).is(':checked'));
        }
    }

    function fillWallAnswers() {
        withWallGlobal = $('#drawing-with-wall').is(':checked');
        if ($('#answer-67').length) {
            $('#drawing-with-wall').prop('checked', $('#answer-67').is(':checked'));
            $('#drawing-without-wall').prop('checked', $('#answer-68').is(':checked'));
            $('#drawing-visible-unselected-walls').prop('checked', $('#answer-67').is(':checked'));
            $('#drawing-with-covering-edges').prop('checked', false);
            $('#drawing-with-parapet-edges').prop('checked', false);
            $('#drawing-with-slope').prop('checked', false);
            $('#recalculate').click();
        } else if ($('#answer-2').length) {
            $('#drawing-with-wall').prop('checked', false);
            $('#drawing-with-covering-edges').prop('checked', false);
            $('#drawing-with-parapet-edges').prop('checked', false);
            $('#drawing-with-slope').prop('checked', false);
            $('#recalculate').click();
        } else {
            $('#drawing-visible-unselected-walls').prop('checked', false);
        }

    }

    function fillCoveringEdgeAnswers() {
        withCoveringEdgeGlobal = $('#drawing-with-covering-edges').is(':checked');
        if ($('#answer-6').length) {
            $('#drawing-with-covering-edges').prop('checked', $('#answer-6').is(':checked'));
            $('#drawing-without-covering-edges').prop('checked', $('#answer-5').is(':checked'));
            $('#drawing-visible-unselected-covering-edges').prop('checked', $('#answer-6').is(':checked'));
            $('#drawing-with-parapet-edges').prop('checked', false);
            $('#drawing-with-slope').prop('checked', false);
            $('#recalculate').click();
        } else if ($('#answer-10').length) {
            $('#drawing-with-covering-edges').prop('checked', withCoveringEdgeGlobal);
            $('#drawing-visible-unselected-covering-edges').prop('checked', false);
            $('#drawing-with-slope').prop('checked', false);
        } else {
            $('#drawing-with-covering-edges').prop('checked', withCoveringEdgeGlobal);
        }

    }

    function fillParapetEdgeAnswers() {
        withParapetGlobal = $('#drawing-with-parapet-edges').is(':checked');
        if ($('#answer-10').length) {
            $('#drawing-with-parapet-edges').prop('checked', $('#answer-10').is(':checked'));
            $('#drawing-without-parapet-edges').prop('checked', $('#answer-9').is(':checked'));
            $('#drawing-visible-unselected-parapets').prop('checked', $('#answer-10').is(':checked'));
            $('#drawing-with-slope').prop('checked', false);
            $('#recalculate').click();
        } else {
            $('#drawing-with-parapet-edges').prop('checked', withParapetGlobal);
            $('#drawing-visible-unselected-parapets').prop('checked', false);
        }
    }

    function fillDoorWindowValues() {
        let doorWidthID = 'inputfield-26';
        let doorWidthNode = $('#' + doorWidthID);
        let windowWidthID = 'inputfield-31';
        let windowWidthNode = $('#' + windowWidthID);
        if (doorWidthNode.length && windowWidthNode.length) {
            changeDoorWindowWidth(doorWidthID, doorWidthNode.val());
            changeDoorWindowWidth(windowWidthID, windowWidthNode.val());
            $('#drawing-with-slope').prop('checked', false);
        } else {
            $('#drawing-answer-11').prop('checked', false);
        }

        let doorWindowSelectIds = [
            'Q_EDGE_1_DOOR-select',
            'Q_EDGE_2_DOOR-select',
            'Q_EDGE_3_DOOR-select',
            'Q_EDGE_4_DOOR-select',
            'Q_EDGE_5_DOOR-select',
            'Q_EDGE_6_DOOR-select',
            'Q_EDGE_1_WINDOW-select',
            'Q_EDGE_2_WINDOW-select',
            'Q_EDGE_3_WINDOW-select',
            'Q_EDGE_4_WINDOW-select',
            'Q_EDGE_5_WINDOW-select',
            'Q_EDGE_6_WINDOW-select',
        ];

        doorWindowSelectIds.forEach(function (item) {
            let doorWindowSelectNode = $('#' + item);
            if (doorWindowSelectNode.length) {
                let optionNode = $('[data-label=\'answer-' + doorWindowSelectNode.val() + '\']');
                changeDrawingSelect(item, optionNode.html());
            }
        });


    }

    function fillFloorDrainValues() {
        withFloorDrainGlobal = $('#' + DomIds.DRAWING_WITHOUT_FLOOR_DRAIN).is(':checked');
        if ($('#answer-25').length) {
            $('#' + DomIds.DRAWING_WITHOUT_FLOOR_DRAIN).prop('checked', $('#answer-25').is(':checked'));
        } else {
            $('#' + DomIds.DRAWING_WITHOUT_FLOOR_DRAIN).prop('checked', withFloorDrainGlobal);
        }

        const individualCoveringSlope = document.getElementById(DomIds.ANSWER_INDIVIDUAL_COVERING_SLOPE);
        const oneSidedCoveringSlope = document.getElementById(DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE);
        const twoSidedCoveringSlope = document.getElementById(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE);

        if (
            (oneSidedCoveringSlope && oneSidedCoveringSlope.checked)
            || (twoSidedCoveringSlope && twoSidedCoveringSlope.checked)
            || (individualCoveringSlope && individualCoveringSlope.checked)
        ) {
            $('#' + DomIds.DRAWING_WITHOUT_FLOOR_DRAIN).prop('checked', false);
        }

        const inputSlopeSummaryH7 = document.getElementById(DomIds.SLOPE_SUMMARY_H7);
        if (inputSlopeSummaryH7) {
            $('#' + DomIds.DRAWING_WITHOUT_FLOOR_DRAIN).prop('checked', true);
        }
    }

    function changeDoorWindowWidth(id, value) {
        let drawingInputId = '#drawing-' + id;
        if (value) {
            $(drawingInputId).val(value);
        }
        $('#recalculate').click();
    }

    function changeFloorDrainPosition(id, value) {
        let drawingInputId = '#drawing-' + id;
        $(drawingInputId).val(value);
        $('#recalculate').click();
    }

    function answerWithoutDoorClick() {
        $('#answer-12').click(function () {
            $('#validate-step').prop('disabled', false);
        });
    }

    function addEventListeners() {
        $('label').click(function () {
            /* IE clickin label doesn't work if input is invisible */
            let radioButton = document.querySelector('#' + this.dataset.label);
            if (radioButton) {
                radioButton.checked = true;
                SlopeDirectionButtons.hideShow();
                fillShapeAnswers();
                fillWallAnswers();
                fillCoveringEdgeAnswers();
                fillParapetEdgeAnswers();
                answerWithoutDoorClick();
                clickTarget(radioButton);
            }
        });


        $('.dimension-input-standard').keyup(function () {
            fillInputFields();
            $('#recalculate').click();
        });
        let $answerId = '';
        $('label[data-label="answer-1"]').click(function () {
            $answerId = 'answer-1';
            let data = {'answerId': $answerId};
            $.post('/edge/deselect-special-l-shape', data);
            $('#drawing-wall-4').prop('checked', false);
            $('#drawing-wall-5').prop('checked', false);
            $('#drawing-covering-edges-4').prop('checked', false);
            $('#drawing-covering-edges-5').prop('checked', false);
            $('#drawing-parapet-edges-4').prop('checked', false);
            $('#drawing-parapet-edges-5').prop('checked', false);
        });

        $('label[data-label="answer-2"]').click(function () {
            $answerId = 'answer-2';
            let data = {'answerId': $answerId};
            $.post('/edge/deselect-special-l-shape', data);
            $('#drawing-wall-4').prop('checked', false);
            $('#drawing-wall-5').prop('checked', false);
            $('#drawing-covering-edges-4').prop('checked', false);
            $('#drawing-covering-edges-5').prop('checked', false);
            $('#drawing-parapet-edges-4').prop('checked', false);
            $('#drawing-parapet-edges-5').prop('checked', false);
        });

        $('label[data-label="answer-4"]').click(function () {
            $answerId = 'answer-4';
            let data = {'answerId': $answerId};
            $.post('/edge/deselect-special-l-shape', data);
            $('#drawing-wall-4').prop('checked', false);
            $('#drawing-wall-5').prop('checked', false);
            $('#drawing-covering-edges-4').prop('checked', false);
            $('#drawing-covering-edges-5').prop('checked', false);
            $('#drawing-parapet-edges-4').prop('checked', false);
            $('#drawing-parapet-edges-5').prop('checked', false);
        });
        $('#inputfield-26').keyup(function (e) {
            changeDoorWindowWidth(e.target.id, e.target.value);
        });
        $('#inputfield-31').keyup(function (e) {
            changeDoorWindowWidth(e.target.id, e.target.value);
        });

        DomUtils.addClickListenerById(DomIds.ANSWER_WITHOUT_SLOPE, function () {
            DrawingSketchConnector.setDrawingHeightValuesToDefault();
            DomUtils.deselectRadioButtonOrCheckBox(DomIds.DRAWING_WITH_SLOPE);
            DomUtils.deselectRadioButtonOrCheckBox(DomIds.DRAWING_WITHOUT_SLOPE);
            DomUtils.deselectRadioButtonOrCheckBox(DomIds.DRAWING_WITH_FLOOR_DRAIN_WITHOUT_LABELS);
            DomUtils.selectRadioButtonOrCheckBox(DomIds.DRAWING_VISIBLE_UNSELECTED_BUTTON);
            DomUtils.recalculateDrawing();
            withSlopeDirectionGlobal = true;

        });

        $('#answer-21').click(function () {
            $('#drawing-with-slope').prop('checked', true);
            $('#drawing-without-slope').prop('checked', false);
            $('#drawing-visible-unselected-slope-buttons').prop('checked', true);
            $('#recalculate').click();
            withSlopeDirectionGlobal = true;

        });
        $('#' + DomIds.ANSWER_INDIVIDUAL_SLOPE).click(function () {
            $('#drawing-with-slope').prop('checked', true);
            $('#drawing-without-slope').prop('checked', false);
            $('#drawing-visible-unselected-slope-buttons').prop('checked', true);
            $('#recalculate').click();
            withSlopeDirectionGlobal = true;
            const drawingSketchConnector = new DrawingSketchConnector();
            drawingSketchConnector.setUndergroundSlopeInitValues();
        });
        $('#' + DomIds.ANSWER_INDIVIDUAL_COVERING_SLOPE).click(function () {
            const drawingSketchConnector = new DrawingSketchConnector();
            drawingSketchConnector.setCoveringSlopeInitValues();
        });
        $('#answer-186').click(function () {
            $('#drawing-with-slope').prop('checked', true);
            $('#drawing-without-slope').prop('checked', false);
            $('#drawing-visible-unselected-slope-buttons').prop('checked', true);
            $('#recalculate').click();
            withSlopeDirectionGlobal = true;
        });

        $('#answer-19').click(function () {
            if ($('#answer-20').prop('checked') === false) {
                deselectSlopeRadios();
            }
        });

        DomUtils.addClickListenerById(DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE, () => {
            DomUtils.recalculateDrawing();
        });
        $('#' + DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE).click(function () {
            $('#drawing-visible-unselected-slope-buttons').prop('checked', true);
            $('#recalculate').click();
            const drawingSketchConnector = new DrawingSketchConnector();
            drawingSketchConnector.setCoveringSlopeInitValues();
        });
        $('#' + DomIds.ANSWER_WITHOUT_COVERING_SLOPE).click(function () {
            $('#drawing-visible-unselected-slope-buttons').prop('checked', true);
            $('#recalculate').click();
        });
        $('#' + DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE).click(function () {
            $('#drawing-without-floor-drain').prop('checked', true);
            $('#' + DomIds.DRAWING_WITHOUT_FLOOR_DRAIN).prop('checked', false);
            $('#recalculate').click();
        });

        $('#answer-24').click(function () {
            const drawingSketchConnector = new DrawingSketchConnector();
            drawingSketchConnector.showHideFloorDrainAndArrows(false);
        });
        $('#answer-25').click(function () {
            const drawingSketchConnector = new DrawingSketchConnector();
            drawingSketchConnector.showHideFloorDrainAndArrows(true);
        });
        $('#' + DomIds.ANSWER_FOLLOWING_COVERING_SLOPE).click(function () {
            const drawingSketchConnector = new DrawingSketchConnector();
            drawingSketchConnector.setCoveringSlopeInitValues();
        });
        $('#inputfield-15').keyup(function (e) {
            changeFloorDrainPosition(e.target.id, e.target.value);
            HintFloorDrain.hideShow();
        });
        $('#inputfield-16').keyup(function (e) {
            changeFloorDrainPosition(e.target.id, e.target.value);
            HintFloorDrain.hideShow();
        });

        $('#btn-plates-view').click(function () {
            showPlatesView();
        });
        $('#btn-substructure-view').click(function () {
            $('#btn-sketch-view').removeClass('selected-view-button');
            $('#btn-substructure-view').addClass('selected-view-button');
            $('#btn-sketch-view').removeClass('selected-view-button');
            $('#canvas-sketch-container').hide();
            $('#canvas-calc-container').show();
        });
        $('#btn-sketch-view').click(function () {
            $('#btn-sketch-view').addClass('selected-view-button');
            $('#btn-substructure-view').removeClass('selected-view-button');
            $('#btn-plates-view').removeClass('selected-view-button');
            $('#canvas-calc-container').hide();
            $('#canvas-sketch-container').show();
        });
        DrawingSketchConnector.setEventListenersToHeightInputs();
    }

    function showPlatesView() {
        $('#btn-sketch-view').addClass('selected-view-button');
        $('#btn-substructure-view').removeClass('selected-view-button');
        $('#btn-sketch-view').removeClass('selected-view-button');
        $('#canvas-sketch-container').hide();
        $('#canvas-calc-container').show();
    }

    function deselectSlopeRadios() {
        $('#drawing-with-slope').prop('checked', false);
        $('#drawing-without-slope').prop('checked', true);
        $('#drawing-visible-unselected-slope-buttons').prop('checked', true);
        $('#directions input').each(function () {
            $(this).prop('checked', false);
        });
        $('#recalculate').click();
        withSlopeDirectionGlobal = false;
    }

    function clickTarget(target) {
        $('#drawing-' + target.id).click();
    }

    function checkDoorsWindowsCountWithChange() {
        $('select').change(function (e) {
            let itemData = $(this).children('option:selected').attr('data-label');
            let itemValue = $(this).children('option:selected').attr('data-count');
            let answerId = getInputsElementId(itemData);

            let selectName = this.name;

            fadeNeedlessAnswers(selectName, itemValue);

            let data = {'answerId': answerId, 'buttonType': 'option'};
            $.post('/configuration-question-answer/save', data)
                .done(function () {
                    $.post('/topic/validate', data)
                        .done(function (responseData) {
                            let errors = JSON.parse(responseData);
                            if (topicHasErrors(errors)) {
                                fillErrorMessages(errors);
                                const loadingCircle = document.getElementById(DomIds.LOADING_CIRCLE);
                                loadingCircle.classList.add(DomClasses.HIDDEN);
                            } else {
                                hideErrorMessages();
                            }
                        });
                });

            let optionNode = $('[data-label=\'answer-' + e.target.value + '\']');
            changeDrawingSelect(e.target.id, optionNode.html());
        });
    }

    function focusDoorsWindowsCount() {
        $('select').focus(function () {
            let itemValue = $(this).children('option:selected').attr('data-count');
            let selectName = this.name;

            fadeNeedlessAnswers(selectName, itemValue);
        });
    }

    function changeDrawingSelect(id, value) {
        let drawingSelectId = '#drawing-' + id;
        $(drawingSelectId).val(value);
        let answer11 = $('#answer-11');
        if (answer11 && answer11[0].checked) {
            $('#drawing-answer-11').prop('checked', true);
        } else {
            $('#drawing-answer-11').prop('checked', false);
        }
        $('#recalculate').click();
    }

    function checkDoorsWindowsCount() {
        if ($('#answer-11').prop('checked') === true) {
            $('.dropdown-items').each(function (index, element) {
                let itemValue = $(element).children('option:selected').attr('data-count');
                let selectName = element.name;

                fadeNeedlessAnswers(selectName, itemValue);
            });
        }
    }

    function fadeNeedlessAnswers(questionUid, checkedValue) {
        let questionType = getInputsElementType(questionUid);
        let questionRow = questionUid.split(questionType);
        let number = 3 - parseInt(checkedValue);
        let pairedQuestionType = '';

        if (questionType === 'DOOR') {
            pairedQuestionType = 'WINDOW';
        } else if (questionType === 'WINDOW') {
            pairedQuestionType = 'DOOR';
        }

        let pairedClass = questionRow[0] + pairedQuestionType;

        $('.' + pairedClass).addClass('hidden');

        $('.' + pairedClass).each(function (index, element) {
            let value = parseInt($(element).attr('data-count'));
            if (value <= number) {
                $(element).removeClass('hidden');
            }
        });
    }

    function getInputsElementType(string) {

        let splttedNodeId = string.split('_');
        let lastPosition = splttedNodeId.length - 1;

        return splttedNodeId[lastPosition];
    }

    function fillShapeSketch(nodeId) {
        const postId = getInputsElementId(nodeId);
        const post = {id: postId};
        $('#shape-sketch').append('');
        let shapeLegendExists = $('#shape-sketch').length;
        if (shapeLegendExists) {
            $.post('/legend/get-shape-legend-image', post)
                .done(function (result) {
                    let path = JSON.parse(result);
                    $('#shape-sketch').html('');
                    if (result !== 'null') {
                        $('#shape-sketch').append('<img src="' + path + '">');
                    }

                });
        }
    }

    function setRealSizeOfTopicContent() {
        if ($('#topic-drawing-container').hasClass('visibility-none')) {
            let realSizeOfTopicContent = $('#topic-answer-image').height();
            $('#topic-content-container').css('max-height', realSizeOfTopicContent + 'px');
        }
    }

    function resizeCanvas() {
        changeNavigationBarPosition();
        let drawingContainer = document.getElementById('topic-drawing-container');
        let topicContentContainer = document.getElementById('topic-content-container');
        let canvasDiv = document.getElementById('canvas-div');
        let canvasSketch = document.getElementById('canvas-sketch');
        let canvasCalc = document.getElementById('canvas');
        let cutsomOffset = 20;
        let header = document.getElementById('header');
        let topicHeadline = document.getElementById('header-content');
        let topicHorizontalLine = document.getElementById('header-hr');
        let topicComment = document.getElementById('topic-comment');
        let footerBelowSketch = document.getElementById('footer-steps');
        let contentHeight = footerBelowSketch.offsetTop + footerBelowSketch.offsetHeight;
        if (drawingContainer && !drawingContainer.classList.contains('visibility-none')) {
            let topicCommentHeight = 0;
            if (topicComment) {
                topicCommentHeight = topicComment.offsetHeight;
            }
            canvasSketch.height = contentHeight - header.offsetHeight - topicHeadline.offsetHeight
                - topicHorizontalLine.offsetHeight - topicCommentHeight;

            let contentWidth = document.querySelector('.wrap');
            let questionContainer = document.getElementById('question-container');
            let answerContainer = document.getElementById('topic-inputs');
            let systemFinderListContainer = document.getElementById('system-finder-list');
            let topicDrawingContainer = document.getElementById('topic-drawing-container');
            let answerContainerWidth = 0;
            if (answerContainer) {
                answerContainerWidth = answerContainer.offsetWidth;
            } else if (systemFinderListContainer) {
                answerContainerWidth = systemFinderListContainer.offsetWidth;
            }

            let drawingWidth = contentWidth.offsetWidth - questionContainer.offsetWidth - answerContainerWidth;
            canvasSketch.width = topicDrawingContainer.offsetWidth - 20;

            let offsetForDesktop = footerBelowSketch.offsetHeight + cutsomOffset;
            if (isIOS()) {
                offsetForDesktop += 60;
            }

            if (isAndroidOperatingSystem()) {
                offsetForDesktop = 0;
            }

            canvasDiv.style.height = contentHeight - header.offsetHeight - topicHeadline.offsetHeight
                - topicHorizontalLine.offsetHeight - offsetForDesktop + 'px';


            let drawingContainerNode = document.getElementById('topic-drawing-container');
            if (drawingContainerNode) {
                let topicCommentOffsetHeight = 0;
                if (topicComment) {
                    topicCommentOffsetHeight = topicComment.offsetHeight;
                }

                let footerBelowSketchHeight = 0;
                if (window.location.search.indexOf('topicId=6') !== -1) {
                    footerBelowSketchHeight = footerBelowSketch.offsetHeight;
                }
                canvasDiv.style.height = drawingContainerNode.offsetHeight + 'px';
                canvasSketch.height = drawingContainerNode.offsetHeight - topicCommentOffsetHeight - 10 - footerBelowSketchHeight;
            }

            if (canvasCalc) {
                let canvasBtnsNode = document.getElementById('canvas-buttons');
                canvasCalc.height = canvasDiv.offsetHeight - canvasBtnsNode.offsetHeight;
                canvasCalc.width = drawingWidth;
            }
        } else if (canvasDiv) {
            canvasDiv.style.height = '';
        }
        setTopicContentContainerScrollBar(topicContentContainer);
        setMainContentPadding();
    }

    function setTopicContentContainerScrollBar(topicContentContainer) {
        if (isMainContentScrollBarVisible()) {
            topicContentContainer.style.overflowY = 'hidden';
        } else {
            topicContentContainer.style.overflowY = 'auto';
        }
    }

    function isMainContentScrollBarVisible() {
        let mainContentNode = document.getElementsByClassName('main-content')[0];
        return mainContentNode.scrollHeight > mainContentNode.offsetHeight;
    }

    function setMainContentPadding() {
        let mainContentNode = document.getElementsByClassName('main-content')[0];
        mainContentNode.style.paddingBottom = '50px';
    }

    function isAndroidOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        // if (/windows phone/i.test(userAgent)) {
        //     return false;
        // }

        if (/android/i.test(userAgent)) {
            return true;
        }

        return false;
    }

    function isIOS() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }
    }

    $(function changeViewportForAndroid() {
        if (isAndroidOperatingSystem()) {
            let viewheight = window.visualViewport.height;
            let viewwidth = window.visualViewport.width;
            let viewport = document.querySelector('meta[name=viewport]');
            let content = 'height=' + viewheight + 'px, width=' + viewwidth + 'px, initial-scale=1.0 ' + 'maximum-scale=1, minimum-scale=1, user-scalable=no, minimal-ui';
            viewport.setAttribute('content', content);
        }
    });
}());


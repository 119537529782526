import {DomClasses, DomIds} from "../utils/DomConstants";

class TopicValidator {
    constructor() {
        this.CONST_HIDDEN_CLASS = 'hidden';
    }

    validate(input) {
        let activeTopicNodeUid = $('.active-topic')[0].getAttribute('data-topic');
        let activeTopicId = this.getInputsElementId(activeTopicNodeUid);
        let topicData = {};
        topicData['topicUid'] = activeTopicId;
        topicData[input.name] = input.value;
        topicData['inputfield'] = 1;

        $.post('/topic/validate', topicData)
            .done(function (responseData) {
                this.handleErrors(responseData);
            });
    }

    handleErrors(response) {
        let errors = JSON.parse(response);
        let nextButtonEnabled = {'validStep': true};
        this.demarkErrorFields();
        if (this.topicHasErrors(errors)) {
            this.markErrorFields(errors);
            this.fillErrorMessages(errors);
            nextButtonEnabled = {'validStep': false};
            const loadingCircle = document.getElementById(DomIds.LOADING_CIRCLE);
            loadingCircle.classList.add(DomClasses.HIDDEN);
        } else {
            this.hideErrorMessages();
        }
        this.enableDisableNextButton(nextButtonEnabled);
    }

    getInputsElementId(idString) {
        if (idString) {
            let splttedNodeId = idString.split('-');
            let lastPosition = splttedNodeId.length - 1;

            return splttedNodeId[lastPosition];
        }
    }

    demarkErrorFields() {
        let inputFields = document.getElementsByClassName('input-field');
        let length = inputFields.length;
        for (let i = 0; i < length; i++) {
            inputFields[i].classList.remove('error-input');
        }
    }

    topicHasErrors(errors) {
        return Object.keys(errors).length > 0;
    }

    markErrorFields(errors) {
        for (let key in errors) {
            if (errors.hasOwnProperty(key)) {
                let errorInput = $('input[name=' + key + ']');
                if (errorInput.length !== 0) {
                    errorInput.addClass('error-input');
                }
            }
        }
    }

    fillErrorMessages(errors) {
        let kosyErrorNode = $('#kosy-error');
        kosyErrorNode.html('');
        for (let key in errors) {
            if (errors.hasOwnProperty(key)) {
                kosyErrorNode.append(errors[key] + '<br>');
                kosyErrorNode.removeClass(this.CONST_HIDDEN_CLASS);
                $('#kosy-image').addClass('hidden');
                $('#kosy-info').addClass('hidden');
                $('#kosy-image-error').removeClass('hidden');
            }
        }
    }

    hideErrorMessages() {
        let kosyErrorNode = $('#kosy-error');
        kosyErrorNode.html('');
        kosyErrorNode.addClass(this.CONST_HIDDEN_CLASS);
    }

    enableDisableNextButton(resultObj) {
        if (resultObj['validStep'] && this.isLastTopic()) {
            this.madeNextStepActive();
        }
    }

    isLastTopic() {
        let allTopics = $('.topic-headline');
        let activeTopic = $('.active-topic')[0];
        let activeTopicId = parseInt(this.getClickedTopicId(activeTopic.id));

        let lastTopic = allTopics[allTopics.length - 1];
        if (lastTopic.className.indexOf('no-visibility') > -1) {
            lastTopic = allTopics[allTopics.length - 2];
        }
        let lastTopicId = parseInt(this.getInputsElementId(lastTopic.id));

        return activeTopicId === lastTopicId;
    }

    madeNextStepActive() {
        $('#to-next-topic').addClass('hidden');
        $('#validate-step').removeClass('hidden');
    }

    getClickedTopicId(idString) {
        return idString.split('-')[2];
    }
}

export default TopicValidator;
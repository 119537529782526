import UrlHelper from './UrlHelper';
import {TopicIds} from '../utils/DomConstants';

class TopicHelper {
    static isUndergroundSlope() {
        const currentTopicID = parseInt(UrlHelper.getSingleUrlParam('topicId'));
        return currentTopicID === TopicIds.SLOPE_UNDERGROUND;
    }

    static isFloorDrain() {
        const currentTopicID = parseInt(UrlHelper.getSingleUrlParam('topicId'));
        return currentTopicID === TopicIds.FLOOR_DRAIN;
    }

    static isCoveringSlope() {
        const currentTopicID = parseInt(UrlHelper.getSingleUrlParam('topicId'));
        return currentTopicID === TopicIds.SLOPE_PLATES;
    }
    static isSummarySlope() {
        const currentTopicID = parseInt(UrlHelper.getSingleUrlParam('topicId'));
        return currentTopicID === TopicIds.SLOPE_SUMMARY;
    }
}

export default TopicHelper;
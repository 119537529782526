import {DomIds} from '../utils/DomConstants';
import {ShapeConstants} from '../utils/ShapeConstants';

class DeckHelper {
    static getShape() {
        const nodeSquare = document.getElementById(DomIds.SQUARE);
        const nodeRectangle = document.getElementById(DomIds.RECTANGLE);
        const nodeLShape = document.getElementById(DomIds.L_SHAPE);
        const nodeLShape90 = document.getElementById(DomIds.L_SHAPE_90);
        const nodeLShape180 = document.getElementById(DomIds.L_SHAPE_180);
        const nodeLShape270 = document.getElementById(DomIds.L_SHAPE_270);
        const nodeTrapezoid = document.getElementById(DomIds.TRAPEZOID);
        const nodeTrapezoid180 = document.getElementById(DomIds.TRAPEZOID_180);

        if (nodeSquare && nodeSquare.checked) {
            return ShapeConstants.RECTANGLE;
        }
        if (nodeRectangle && nodeRectangle.checked) {
            return ShapeConstants.RECTANGLE;
        }
        if (nodeLShape && nodeLShape.checked) {
            return ShapeConstants.L_SHAPE;
        }
        if (nodeLShape90 && nodeLShape90.checked) {
            return ShapeConstants.L_SHAPE_90;
        }
        if (nodeLShape180 && nodeLShape180.checked) {
            return ShapeConstants.L_SHAPE_180;
        }
        if (nodeLShape270 && nodeLShape270.checked) {
            return ShapeConstants.L_SHAPE_270;
        }
        if (nodeTrapezoid && nodeTrapezoid.checked) {
            return ShapeConstants.TRAPEZOID;
        }
        if (nodeTrapezoid180 && nodeTrapezoid180.checked) {
            return ShapeConstants.TRAPEZOID_180;
        }
    }
}

export default DeckHelper;
import {DomIds} from './DomConstants';

export class DomUtils {

    static getElementByAttributeAndValue(dataAttribute, value) {
        return document.querySelector(`*[data-${dataAttribute}="${value}"]`);
    }

    static isNodeChecked(node) {
        return !!(node && node.checked);
    }

    static addClickListenerById(id, callback) {
        const node = document.getElementById(id);
        if (node) {
            node.addEventListener('click', callback);
        }
    }

    static deselectRadioButtonOrCheckBox(id) {
        const node = document.getElementById(id);
        if (node) {
            node.checked = false;
        }
    }

    static selectRadioButtonOrCheckBox(id) {
        const node = document.getElementById(id);
        if (node) {
            node.checked = true;
        }
    }

    static recalculateDrawing() {
        const node = document.getElementById(DomIds.Recalculate);
        if (node) {
            node.click();
        }
    }

    static isChecked(id) {
        const node = document.getElementById(id);
        if (node) {
            return node.checked;
        }

        return false;
    }
}
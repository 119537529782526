export const DomIds = {
    KOSY_ERROR: 'kosy-error',
    KOSY_INFO : 'kosy-info',
    KOSY_IMAGE : 'kosy-image',
    TOPIC_QUESTION : 'topic-question',
    TOPIC_INPUTS : 'topic-inputs',
    ANSWER_DOOR_WINDOW_ID : 'answer-11',
    SLOPE_SKETCH : 'slope-sketch',
    SQUARE: 'drawing-answer-2',
    RECTANGLE : 'drawing-answer-1',
    L_SHAPE : 'drawing-answer-3',
    L_SHAPE_90 : 'drawing-answer-191',
    L_SHAPE_180 : 'drawing-answer-192',
    L_SHAPE_270 : 'drawing-answer-193',
    TRAPEZOID : 'drawing-answer-4',
    TRAPEZOID_180 : 'drawing-answer-194',
    ANSWER_WITHOUT_SLOPE: 'answer-18',
    ANSWER_WITH_SLOPE: 'answer-19',
    ANSWER_INDIVIDUAL_SLOPE: 'answer-20',
    ANSWER_ONE_SIDED_SLOPE: 'answer-21',
    ANSWER_WITH_FLOOR_DRAIN: 'answer-25',
    ANSWER_TWO_SIDED_SLOPE: 'answer-186',
    ANSWER_WITH_COVERING_SLOPE: 'answer-150',
    ANSWER_WITHOUT_COVERING_SLOPE: 'answer-151',
    ANSWER_ONE_SIDED_COVERING_SLOPE: 'answer-152',
    ANSWER_INDIVIDUAL_COVERING_SLOPE: 'answer-153',
    ANSWER_FOLLOWING_COVERING_SLOPE: 'answer-187',
    ANSWER_TWO_SIDED_COVERING_SLOPE: 'answer-188',
    SLOPE_DIRECTION_CONTAINER: 'slope-direction-container',
    SLOPE_DIRECTION_UP: 'slope-direction-up',
    SLOPE_DIRECTION_DOWN: 'slope-direction-down',
    SLOPE_DIRECTION_LEFT: 'slope-direction-left',
    SLOPE_DIRECTION_RIGHT: 'slope-direction-right',
    SLOPE_VALUE: 'slope-value',
    FLOOR_DRAIN_X: 'inputfield-15',
    FLOOR_DRAIN_Y: 'inputfield-16',
    Height_H1: 'inputfield-37',
    Height_H2: 'inputfield-38',
    Height_H3: 'inputfield-39',
    Height_H4: 'inputfield-40',
    Height_H5: 'inputfield-55',
    Height_H6: 'inputfield-56',
    Height_H7: 'inputfield-34',
    SLOPE_SUMMARY_H1: 'inputfield-50',
    SLOPE_SUMMARY_H2: 'inputfield-51',
    SLOPE_SUMMARY_H3: 'inputfield-52',
    SLOPE_SUMMARY_H4: 'inputfield-53',
    SLOPE_SUMMARY_H5: 'inputfield-59',
    SLOPE_SUMMARY_H6: 'inputfield-60',
    SLOPE_SUMMARY_H7: 'inputfield-54',
    Height_COVERING_SLOPE_H1: 'inputfield-44',
    Height_COVERING_SLOPE_H2: 'inputfield-45',
    Height_COVERING_SLOPE_H3: 'inputfield-47',
    Height_COVERING_SLOPE_H4: 'inputfield-48',
    Height_COVERING_SLOPE_H5: 'inputfield-57',
    Height_COVERING_SLOPE_H6: 'inputfield-58',
    Height_COVERING_SLOPE_H7: 'inputfield-49',
    Height_COVERING_SLOPE_H7_PARENT: 'inputfieldAndLabel-49',
    DRAWING_H1: 'h1-slope',
    DRAWING_H2: 'h2-slope',
    DRAWING_H3: 'h3-slope',
    DRAWING_H4: 'h4-slope',
    DRAWING_H5: 'h5-slope',
    DRAWING_H6: 'h6-slope',
    DRAWING_H7: 'h7-slope',
    DRAWING_WITH_SLOPE: 'drawing-with-slope',
    DRAWING_WITHOUT_SLOPE: 'drawing-without-slope',
    DRAWING_WITH_FLOOR_DRAIN: 'drawing-with-floor-drain',
    DRAWING_WITHOUT_FLOOR_DRAIN: 'drawing-without-floor-drain',
    DRAWING_WITH_FLOOR_DRAIN_WITHOUT_LABELS: 'drawing-with-floor-drain-without-labels',
    DRAWING_FLOOR_DRAIN_X: 'drawing-inputfield-15',
    DRAWING_FLOOR_DRAIN_Y: 'drawing-inputfield-16',
    DRAWING_VISIBLE_UNSELECTED_BUTTON: 'drawing-visible-unselected-slope-buttons',
    A1: 'drawing-inputfield-7',
    A2: 'drawing-inputfield-8',
    A3: 'drawing-inputfield-9',
    B1: 'drawing-inputfield-10',
    B2: 'drawing-inputfield-11',
    RenderSlopeLabels: 'render-slope-labels',
    Recalculate: 'recalculate',
    Render2PentSlopeLine: 'render-2-pent-slope-line',
    HINT_FLOOR_DRAIN_DISTANCE: 'hint-floor-drain-distance',
    LOADING_CIRCLE: 'loading-circle'
};

export const TopicIds = {
    SLOPE_UNDERGROUND : 6,
    FLOOR_DRAIN : 7,
    SLOPE_PLATES : 30,
    SLOPE_SUMMARY : 32,
};

export const TopicPositions  = {
    T_A_SLOPE: '7',
    T_A_FLOOR_DRAIN: '8',
    T_A_SLOPE_COVERING: '9',
    T_A_SLOPE_SUMMARY: '10',
};

export const DomClasses = {
    HIDDEN : 'hidden',
    TOPIC_COMMENT : 'topic-comment',
    TOPIC_DESCRIPTION : 'topic-description',
    BLUE_COLOR : 'blue-color',
    NOTES_DEMAND : 'notes-demand',
    HINT_IF_OTHER_ANSWERS_SHOWN : 'hint-if-other-answers-shown',
    HINT_ONLY_ONE_ANSWER : 'hint-only-one-answer',
    HINT_SUMMARY_SLOPE_TEXT : 'summary-slopeText',
    INVISIBLE : 'invisible',
    SHOW_SCROLL : 'showScroll'
};

export const DataAttributes = {
    HINT_OTHER_ANSWERS: 'hintotheranswers',
    SLOPE_DIRECTION: 'slopedirection',
    Slope: 'slope'
};

export const AnswerStyles = {
    DROP_DOWN_LIST: 'drop-down-list'
};


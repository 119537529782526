import {DomClasses, DomIds} from '../utils/DomConstants';

class PermanentText {
    render(resultObj) {
        if (this.hasNotOnlyOneAnswerText()) {
            const topicContentsNode = document.getElementById(DomIds.TOPIC_INPUTS);
            const questions = resultObj['questions'];
            const permanentTextKey = 'permanentText';
            let text = '<div class="hint-permanent-text">';
            let countOfHints = 0;

            for (const questionsKey in questions) {
                if (questions.hasOwnProperty(questionsKey) && questions[questionsKey].hasOwnProperty(permanentTextKey)) {
                    const question = questions[questionsKey];
                    const texts = question[permanentTextKey];
                    for (const textsKey in texts) {
                        if (texts.hasOwnProperty(textsKey)) {
                            text += `<div>${texts[textsKey]}</div>`;
                            countOfHints++;
                        }
                    }

                }
            }

            text += '</div>';

            if (countOfHints > 0) {
                topicContentsNode.insertAdjacentHTML('beforeend', text);
            }
        }

    }

    hasNotOnlyOneAnswerText() {
        const onyOneAnswerHints = document.querySelectorAll('.' + DomClasses.HINT_ONLY_ONE_ANSWER);

        return onyOneAnswerHints.length === 0;
    }
}

export default PermanentText;
import {DataAttributes, DomClasses, DomIds} from '../utils/DomConstants';
import {ShapeConstants} from '../utils/ShapeConstants';
import Fetch from '../helper/Fetch';
import DrawingSketchConnector from '../helper/DrawingSketchConnector';
import DeckHelper from '../helper/DeckHelper';
import TopicHelper from '../helper/TopicHelper';
import NumberInputfieldHelper from '../helper/NumberInputfieldHelper';
import {DomUtils} from '../utils/DomUtils';
import TopicValidator from "../request/TopicValidator";
import topicValidator from "../request/TopicValidator";

class SlopeDirectionButtons {
    static noSlopeDirectionButton(radioId) {
        return radioId !== DomIds.SLOPE_DIRECTION_UP
            && radioId !== DomIds.SLOPE_DIRECTION_DOWN
            && radioId !== DomIds.SLOPE_DIRECTION_LEFT
            && radioId !== DomIds.SLOPE_DIRECTION_RIGHT;
    }

    static hideShow() {
        if (SlopeDirectionButtons.isOneSided()) {
            const slopeDirectionButtons = new SlopeDirectionButtons();
            slopeDirectionButtons.renderOneSided();
            slopeDirectionButtons.setInputFieldsReadonly(true);
        } else if (SlopeDirectionButtons.isTwoSided()) {
            const slopeDirectionButtons = new SlopeDirectionButtons();
            slopeDirectionButtons.renderTwoSided();
            slopeDirectionButtons.setInputFieldsReadonly(true);
        } else {
            const slopeDirectionButtons = new SlopeDirectionButtons();
            slopeDirectionButtons.setInputFieldsReadonly(false);
            const node = document.getElementById(DomIds.SLOPE_DIRECTION_CONTAINER);
            if (node) {
                node.remove();
            }
        }
    }

    static isOneSided() {
        const undergroundSlopeNode = document.getElementById(DomIds.ANSWER_ONE_SIDED_SLOPE);
        if (DomUtils.isNodeChecked(undergroundSlopeNode)) {
            return true;
        }

        const coveringSlopeNode = document.getElementById(DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE);
        return DomUtils.isNodeChecked(coveringSlopeNode);
    }

    static isTwoSided() {
        const undergroundSlopeNode = document.getElementById(DomIds.ANSWER_TWO_SIDED_SLOPE);
        if (DomUtils.isNodeChecked(undergroundSlopeNode)) {
            return true;
        }

        const coveringSlopeNode = document.getElementById(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE);
        return DomUtils.isNodeChecked(coveringSlopeNode);
    }

    constructor() {
        this.UP = 'up';
        this.DOWN = 'down';
        this.LEFT = 'left';
        this.RIGHT = 'right';
        this.TWO_PENT_SLOPE_LEFT_UP = 'two-left';
        this.TWO_PENT_SLOPE_RIGHT_DOWN = 'two-right';

        this.shape = DeckHelper.getShape();
        this.slopeValue = 0;
        this.direction = DomIds.SLOPE_DIRECTION_LEFT;
        this.systemFinderHeight = 0;
    }

    render() {
        const oneSidedSlope = document.getElementById(DomIds.ANSWER_ONE_SIDED_SLOPE);
        const twoSidedSlope = document.getElementById(DomIds.ANSWER_TWO_SIDED_SLOPE);
        const oneSidedCoveringSlope = document.getElementById(DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE);
        const twoSidedCoveringSlope = document.getElementById(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE);

        if (
            (oneSidedSlope && oneSidedSlope.checked)
            || (oneSidedCoveringSlope && oneSidedCoveringSlope.checked)
        ) {
            this.renderOneSided();
        } else if (
            (twoSidedSlope && twoSidedSlope.checked)
            || (twoSidedCoveringSlope && twoSidedCoveringSlope.checked)) {
            this.renderTwoSided();
        }
    }

    renderOneSided() {
        if (
            this.isSlopeSelected(DomIds.ANSWER_ONE_SIDED_SLOPE)
            || this.isSlopeSelected(DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE)
        ) {
            this.fetchDataAndRender(false);
        } else {
            this.setInputFieldsReadonly(false);
        }
    }

    renderTwoSided() {
        if (
            this.isSlopeSelected(DomIds.ANSWER_TWO_SIDED_SLOPE)
            || this.isSlopeSelected(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE)
        ) {
            this.fetchDataAndRender(true);
        } else {
            this.setInputFieldsReadonly(false);
        }
    }

    fetchDataAndRender(twoSided) {
        let twoSidedUrlParam = 'false';
        if (twoSided) {
            twoSidedUrlParam = 'true';
        }

        const headers = {
            'x-pretty-print': '2'
        };
        const slopeType = this.getSlopeType();
        const fetcher = new Fetch('slope-direction/get-data?twoSided=' + twoSidedUrlParam + '&slopeType=' + slopeType, 'GET', headers);
        fetcher.proceed((response) => {
            this.slopeValue = parseInt(response['slope']);
            this.direction = response['direction'];
            this.systemFinderHeight = parseInt(response['finderConstructionHeight']);
            if (twoSided) {
                this.renderTwoSidedSlopeImages();
            } else {
                this.renderOneSidedSlopeImages();
            }
            this.renderSlopeInput();
            this.setEventListeners();
            this.setInputFieldsReadonly(true);
        });
    }

    isSlopeSelected(id) {
        const node = document.getElementById(id);
        return node && node.checked;
    }

    renderOneSidedSlopeImages() {
        const oldContainer = document.getElementById(DomIds.SLOPE_DIRECTION_CONTAINER);
        if (oldContainer) {
            oldContainer.remove();
        }

        const container = document.createElement('div');
        container.id = DomIds.SLOPE_DIRECTION_CONTAINER;
        this.addContainerLabel(container);

        let buttonsHtml = '';
        buttonsHtml += this.renderButton(this.LEFT);
        buttonsHtml += this.renderButton(this.RIGHT);
        buttonsHtml += this.renderButton(this.UP);
        buttonsHtml += this.renderButton(this.DOWN);

        container.insertAdjacentHTML('beforeend', buttonsHtml);

        const inputContainerId = this.isSlopeSelected(DomIds.ANSWER_ONE_SIDED_SLOPE) ? 'IG_CONSTRUCTION_HEIGHT_SLOPE' : 'IG_SLOPE_COVERING';
        const inputContainer = document.getElementById(inputContainerId);
        const clearfix = document.createElement('div');
        clearfix.classList.add('clearfix');
        inputContainer.after(clearfix);
        inputContainer.after(container);
        this.calculateHeightValues(this.slopeValue);
    }

    renderTwoSidedSlopeImages() {
        const oldContainer = document.getElementById(DomIds.SLOPE_DIRECTION_CONTAINER);
        if (oldContainer) {
            oldContainer.remove();
        }

        const container = document.createElement('div');
        container.id = DomIds.SLOPE_DIRECTION_CONTAINER;
        this.addContainerLabel(container);

        let buttonsHtml = '';
        buttonsHtml += this.renderButton(this.TWO_PENT_SLOPE_LEFT_UP);
        buttonsHtml += this.renderButton(this.TWO_PENT_SLOPE_RIGHT_DOWN);

        container.insertAdjacentHTML('beforeend', buttonsHtml);

        const inputContainerId = this.isSlopeSelected(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE) ? 'IG_SLOPE_COVERING' : 'IG_CONSTRUCTION_HEIGHT_SLOPE';
        const inputContainer = document.getElementById(inputContainerId);
        const clearfix = document.createElement('div');
        clearfix.classList.add('clearfix');
        inputContainer.after(clearfix);
        inputContainer.after(container);
        this.calculateHeightValues(this.slopeValue);
    }

    addContainerLabel(container) {
        container.insertAdjacentHTML('beforeend', '<div>Gefällerichtung </br>anklicken</div>');
    }

    renderButton(direction) {
        const active = direction === this.direction ? 'active' : 'inactive';

        return `<div>
                    <input
                     type="radio"
                     id="slope-direction-${direction}"
                     name="slope-direction"
                     data-${DataAttributes.SLOPE_DIRECTION}="${direction}"
                     class="${DomClasses.HIDDEN}"
                     />
                    <label for="slope-direction-${direction}">
                        ${this.renderImage(direction, active)}
                    </label>
                </div>`;
    }

    renderImage(direction, activeInactive) {
        return `<img id="slope-${direction}" src="/images/slope/arrow-${this.shape}-${direction}-${activeInactive}.png" class="img-responsive answer-image">`;
    }

    renderSlopeInput() {
        const container = document.getElementById(DomIds.SLOPE_DIRECTION_CONTAINER);

        const html = `<div>
                        <div class="row">
                            <label for="${DomIds.SLOPE_VALUE}" class="input-field-label">Gefällehöhe eingeben</label>
                        </div>
                        <div class="row">
                            <input type="number" id="${DomIds.SLOPE_VALUE}"  class="dimension-input-standard input-field" value="${this.slopeValue}" step="1">
                            <span>mm</span>
                        </div>
                      </div>`;

        container.insertAdjacentHTML('beforeend', html);
    }

    setEventListeners() {
        this.setButtonEventListener();
        this.setSlopInputEventListener();
    }

    setButtonEventListener() {
        const nodes = document.querySelectorAll('[data-' + DataAttributes.SLOPE_DIRECTION + ']');
        nodes.forEach((node) => {
            node.addEventListener('click', (event) => {
                this.direction = node.dataset[DataAttributes.SLOPE_DIRECTION];

                this.changeButtonImage(event.target.id);
                this.renderSlopeInput();
                this.setEventListeners();

                const slopeInputNode = document.getElementById(DomIds.SLOPE_VALUE);
                this.calculateHeightValues(slopeInputNode.value);
                DrawingSketchConnector.setEventListenersToHeightInputs();
                this.postData();


            });
        });
    }
    changeButtonImage(id) {
        const label = document.querySelector('label[for=' + id + ']');
        label.html = this.renderImage(this.direction);
        document.getElementById(DomIds.SLOPE_DIRECTION_CONTAINER).remove();
        if (this.direction === this.TWO_PENT_SLOPE_LEFT_UP || this.direction === this.TWO_PENT_SLOPE_RIGHT_DOWN) {
            this.renderTwoSidedSlopeImages();
        } else {
            this.renderOneSidedSlopeImages();
        }

        const image = document.getElementById(`slope-${this.direction}`);
        image.src = '/images/slope/arrow-' + this.shape + '-' + this.direction + '-active.png';
    }

    setInputFieldsReadonly(readonly) {
        let h1 = document.getElementById(DomIds.Height_H1);
        let h2 = document.getElementById(DomIds.Height_H2);
        let h3 = document.getElementById(DomIds.Height_H3);
        let h4 = document.getElementById(DomIds.Height_H4);
        let h5 = document.getElementById(DomIds.Height_H5);
        let h6 = document.getElementById(DomIds.Height_H6);

        if (!h1) {
            h1 = document.getElementById(DomIds.Height_COVERING_SLOPE_H1);
            h2 = document.getElementById(DomIds.Height_COVERING_SLOPE_H2);
            h3 = document.getElementById(DomIds.Height_COVERING_SLOPE_H3);
            h4 = document.getElementById(DomIds.Height_COVERING_SLOPE_H4);
            h5 = document.getElementById(DomIds.Height_COVERING_SLOPE_H5);
            h6 = document.getElementById(DomIds.Height_COVERING_SLOPE_H6);
        }

        if (h1) {
            h1.readOnly = readonly;
        }
        if (h2) {
            h2.readOnly = readonly;
        }
        if (h3) {
            h3.readOnly = readonly;
        }
        if (h4) {
            h4.readOnly = readonly;
        }
        if (h5) {
            h5.readOnly = readonly;
        }
        if (h6) {
            h6.readOnly = readonly;
        }
    }

    postData() {
        const data = {};//new URLSearchParams();
        data.direction = this.direction;
        data.slope = this.slopeValue.toString();

        const slopeType = this.getSlopeType();
        data.slopeType = slopeType;
        const inputValues = {};

        if(slopeType === 'covering-slope') {
            inputValues[DomIds.Height_COVERING_SLOPE_H1] = this.appendDataWithInputValue(DomIds.Height_COVERING_SLOPE_H1);
            inputValues[DomIds.Height_COVERING_SLOPE_H2] = this.appendDataWithInputValue(DomIds.Height_COVERING_SLOPE_H2);
            inputValues[DomIds.Height_COVERING_SLOPE_H3] = this.appendDataWithInputValue(DomIds.Height_COVERING_SLOPE_H3);
            inputValues[DomIds.Height_COVERING_SLOPE_H4] = this.appendDataWithInputValue(DomIds.Height_COVERING_SLOPE_H4);
            inputValues[DomIds.Height_COVERING_SLOPE_H5] = this.appendDataWithInputValue(DomIds.Height_COVERING_SLOPE_H5);
            inputValues[DomIds.Height_COVERING_SLOPE_H6] = this.appendDataWithInputValue(DomIds.Height_COVERING_SLOPE_H6);
            inputValues[DomIds.Height_COVERING_SLOPE_H7] = this.appendDataWithInputValue(DomIds.Height_COVERING_SLOPE_H7);
        } else {
            inputValues[DomIds.Height_H1] = this.appendDataWithInputValue(DomIds.Height_H1);
            inputValues[DomIds.Height_H2] = this.appendDataWithInputValue(DomIds.Height_H2);
            inputValues[DomIds.Height_H3] = this.appendDataWithInputValue(DomIds.Height_H3);
            inputValues[DomIds.Height_H4] = this.appendDataWithInputValue(DomIds.Height_H4);
            inputValues[DomIds.Height_H5] = this.appendDataWithInputValue(DomIds.Height_H5);
            inputValues[DomIds.Height_H6] = this.appendDataWithInputValue(DomIds.Height_H6);
        }
        data.inputValues = JSON.stringify(inputValues);

        $.post('/slope-direction/save-in-session', data)
            .done((response) => {
                const topicValidator = new TopicValidator();
                topicValidator.handleErrors(response);
            });
    }

    getSlopeType() {
        let slopeType = 'underground-slope';
        if (TopicHelper.isUndergroundSlope()) {
            slopeType = 'underground-slope';
        }

        if (TopicHelper.isCoveringSlope()) {
            slopeType = 'covering-slope';
        }

        return slopeType;
    }

    appendDataWithInputValue(id) {
        const input = document.getElementById(id);
        if (input) {
            return input.value;
        }

        return false;
    }

    setSlopInputEventListener() {
        const node = document.getElementById(DomIds.SLOPE_VALUE);
        node.addEventListener('keyup', this.delay((event) => {
            NumberInputfieldHelper.isInteger(event.target.value, event.target.id);
            this.calculateHeightValues(event.target.value);
            DrawingSketchConnector.setEventListenersToHeightInputs();
            this.postData();
        }, 500));

        node.addEventListener('keyup', (event) => {
            NumberInputfieldHelper.isInteger(event.target.value, event.target.id);
        });
    }


    delay(fn, ms) {
        let timer = 0;
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(fn.bind(this, ...args), ms || 0);
        };
    }

    calculateHeightValues(value) {
        this.slopeValue = parseInt(value);
        switch (this.shape) {
        case ShapeConstants.RECTANGLE:
            this.calculateHeightsForRectangle();
            break;
        case ShapeConstants.L_SHAPE:
        case ShapeConstants.L_SHAPE_90:
        case ShapeConstants.L_SHAPE_180:
        case ShapeConstants.L_SHAPE_270:
            this.calculateHeightForLShape();
            break;
        case ShapeConstants.TRAPEZOID:
        case ShapeConstants.TRAPEZOID_180:
            this.calculateHeightsForTrapezoid();
            break;
        }

    }

    calculateHeightsForRectangle() {
        let h1 = document.getElementById(DomIds.Height_H1);
        let h2 = document.getElementById(DomIds.Height_H2);
        let h3 = document.getElementById(DomIds.Height_H3);
        let h4 = document.getElementById(DomIds.Height_H4);
        let startingHeight = this.systemFinderHeight;

        if (this.isSlopeSelected(DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE) || this.isSlopeSelected(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE)) {
            h1 = document.getElementById(DomIds.Height_COVERING_SLOPE_H1);
            h2 = document.getElementById(DomIds.Height_COVERING_SLOPE_H2);
            h3 = document.getElementById(DomIds.Height_COVERING_SLOPE_H3);
            h4 = document.getElementById(DomIds.Height_COVERING_SLOPE_H4);
            startingHeight = 0;
        }

        switch (this.direction) {
        case this.UP:
            h1.value = Math.round(startingHeight + this.slopeValue);
            h2.value = Math.round(startingHeight + this.slopeValue);
            h3.value = Math.round(startingHeight);
            h4.value = Math.round(startingHeight);
            break;
        case this.DOWN:
            h1.value = Math.round(startingHeight);
            h2.value = Math.round(startingHeight);
            h3.value = Math.round(startingHeight + this.slopeValue);
            h4.value = Math.round(startingHeight + this.slopeValue);
            break;
        case this.LEFT:
        case this.TWO_PENT_SLOPE_RIGHT_DOWN:
            h1.value = Math.round(startingHeight + this.slopeValue);
            h2.value = Math.round(startingHeight);
            h3.value = Math.round(startingHeight);
            h4.value = Math.round(startingHeight + this.slopeValue);
            break;
        case this.RIGHT:
        case this.TWO_PENT_SLOPE_LEFT_UP:
            h1.value = Math.round(startingHeight);
            h2.value = Math.round(startingHeight + this.slopeValue);
            h3.value = Math.round(startingHeight + this.slopeValue);
            h4.value = Math.round(startingHeight);
            break;
        }
        DrawingSketchConnector.mapInputValues(h1.id, h1.value);
        DrawingSketchConnector.mapInputValues(h2.id, h2.value);
        DrawingSketchConnector.mapInputValues(h3.id, h3.value);
        DrawingSketchConnector.mapInputValues(h4.id, h4.value);
    }

    calculateHeightForLShape() {
        let h1 = document.getElementById(DomIds.Height_H1);
        let h2 = document.getElementById(DomIds.Height_H2);
        let h3 = document.getElementById(DomIds.Height_H3);
        let h4 = document.getElementById(DomIds.Height_H4);
        let h5 = document.getElementById(DomIds.Height_H5);
        let h6 = document.getElementById(DomIds.Height_H6);
        let startingHeight = this.systemFinderHeight;

        const a1 = parseInt(document.getElementById(DomIds.A1).value);
        const a2 = parseInt(document.getElementById(DomIds.A2).value);
        const b1 = parseInt(document.getElementById(DomIds.B1).value);
        const b2 = parseInt(document.getElementById(DomIds.B2).value);

        if (this.isSlopeSelected(DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE) || this.isSlopeSelected(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE)) {
            h1 = document.getElementById(DomIds.Height_COVERING_SLOPE_H1);
            h2 = document.getElementById(DomIds.Height_COVERING_SLOPE_H2);
            h3 = document.getElementById(DomIds.Height_COVERING_SLOPE_H3);
            h4 = document.getElementById(DomIds.Height_COVERING_SLOPE_H4);
            h5 = document.getElementById(DomIds.Height_COVERING_SLOPE_H5);
            h6 = document.getElementById(DomIds.Height_COVERING_SLOPE_H6);
            startingHeight = 0;
        }

        switch (this.direction) {
        case this.UP:
            h1.value = Math.round(startingHeight + this.slopeValue);
            h2.value = Math.round(startingHeight + this.slopeValue);
            h3.value = Math.round(startingHeight);
            h4.value = Math.round(startingHeight);
            h5.value = Math.round(startingHeight + this.calculateHeightOfShortSlope(b1 + b2, b2));
            h6.value = Math.round(h5.value);
            break;
        case this.DOWN:
            h1.value = Math.round(startingHeight);
            h2.value = Math.round(startingHeight);
            h3.value = Math.round(startingHeight + this.slopeValue);
            h4.value = Math.round(startingHeight + this.slopeValue);
            h5.value = Math.round(startingHeight + this.calculateHeightOfShortSlope(b1 + b2, b1));
            h6.value = Math.round(h5.value);
            break;
        case this.LEFT:
            h1.value = Math.round(startingHeight + this.slopeValue);
            h4.value = Math.round(startingHeight + this.slopeValue);
            h2.value = Math.round(startingHeight + this.calculateHeightOfShortSlope(a1 + a2, a2));
            h3.value = Math.round(startingHeight);
            h5.value = Math.round(h2.value);
            h6.value = Math.round(startingHeight);
            break;
        case this.TWO_PENT_SLOPE_RIGHT_DOWN:
            h1.value = Math.round(startingHeight + this.slopeValue);
            h3.value = Math.round(startingHeight + this.slopeValue);
            h4.value = Math.round(startingHeight + this.slopeValue);
            h2.value = Math.round(startingHeight);
            h5.value = Math.round(startingHeight);
            h6.value = Math.round(startingHeight);
            break;
        case this.RIGHT:
            h1.value = Math.round(startingHeight);
            h4.value = Math.round(startingHeight);
            h2.value = Math.round(startingHeight + this.calculateHeightOfShortSlope(a1 + a2, a1));
            h3.value = Math.round(startingHeight + this.slopeValue);
            h5.value = Math.round(h2.value);
            h6.value = Math.round(startingHeight + this.slopeValue);
            break;
        case this.TWO_PENT_SLOPE_LEFT_UP:
            h1.value = Math.round(startingHeight);
            h3.value = Math.round(startingHeight);
            h4.value = Math.round(startingHeight);
            h2.value = Math.round(startingHeight + this.slopeValue);
            h5.value = Math.round(startingHeight + this.slopeValue);
            h6.value = Math.round(startingHeight + this.slopeValue);
            break;
        }
        DrawingSketchConnector.mapInputValues(h1.id, h1.value);
        DrawingSketchConnector.mapInputValues(h2.id, h2.value);
        DrawingSketchConnector.mapInputValues(h3.id, h3.value);
        DrawingSketchConnector.mapInputValues(h4.id, h4.value);
        DrawingSketchConnector.mapInputValues(h5.id, h5.value);
        DrawingSketchConnector.mapInputValues(h6.id, h6.value);
    }

    calculateHeightsForTrapezoid() {
        let h1 = document.getElementById(DomIds.Height_H1);
        let h2 = document.getElementById(DomIds.Height_H2);
        let h3 = document.getElementById(DomIds.Height_H3);
        let h4 = document.getElementById(DomIds.Height_H4);
        let startingHeight = this.systemFinderHeight;

        if (this.isSlopeSelected(DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE) || this.isSlopeSelected(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE)) {
            h1 = document.getElementById(DomIds.Height_COVERING_SLOPE_H1);
            h2 = document.getElementById(DomIds.Height_COVERING_SLOPE_H2);
            h3 = document.getElementById(DomIds.Height_COVERING_SLOPE_H3);
            h4 = document.getElementById(DomIds.Height_COVERING_SLOPE_H4);
            startingHeight = 0;
        }

        const a1 = parseInt(document.getElementById(DomIds.A1).value);
        const a2 = parseInt(document.getElementById(DomIds.A2).value);
        const a3 = parseInt(document.getElementById(DomIds.A3).value);
        const completeLength = a1 + a2 + a3;

        switch (this.direction) {
        case this.UP:
            h1.value = Math.round(startingHeight + this.slopeValue);
            h2.value = Math.round(startingHeight + this.slopeValue);
            h3.value = Math.round(startingHeight);
            h4.value = Math.round(startingHeight);
            break;
        case this.DOWN:
            h1.value = Math.round(startingHeight);
            h2.value = Math.round(startingHeight);
            h3.value = Math.round(startingHeight + this.slopeValue);
            h4.value = Math.round(startingHeight + this.slopeValue);
            break;
        case this.LEFT:
            h1.value = Math.round(startingHeight + this.calculateHeightOfShortSlope(completeLength, a2 + a3));
            h2.value = Math.round(startingHeight + this.calculateHeightOfShortSlope(completeLength, a3));
            h3.value = Math.round(startingHeight);
            h4.value = Math.round(startingHeight + this.slopeValue);
            break;
        case this.RIGHT:
            h1.value = Math.round(startingHeight + this.calculateHeightOfShortSlope(completeLength, a1));
            h2.value = Math.round(startingHeight + this.calculateHeightOfShortSlope(completeLength, a1 + a2));
            h3.value = Math.round(startingHeight + this.slopeValue);
            h4.value = Math.round(startingHeight);
            break;
        }
        DrawingSketchConnector.mapInputValues(h1.id, h1.value);
        DrawingSketchConnector.mapInputValues(h2.id, h2.value);
        DrawingSketchConnector.mapInputValues(h3.id, h3.value);
        DrawingSketchConnector.mapInputValues(h4.id, h4.value);
    }

    calculateHeightOfShortSlope(completeLength, edgeLength) {
        const hypot = Math.hypot(this.slopeValue, completeLength);
        const alpha = Math.asin(this.slopeValue / hypot) * (180 / Math.PI);

        const beta = 90 - alpha;
        const sinBeta = Math.sin(beta * (Math.PI / 180));
        const c = edgeLength / sinBeta;

        const a = Math.sqrt(-(edgeLength * edgeLength) + (c * c));

        return Math.round(a * 100) / 100;
    }
}

export default SlopeDirectionButtons;
import {DomClasses} from '../utils/DomConstants';
import TopicHelper from '../helper/TopicHelper';

class Comment {
    render(topicComment)
    {
        const node = document.querySelector('.' + DomClasses.TOPIC_COMMENT);
        node.innerHTML = topicComment;
        
        if (TopicHelper.isSummarySlope()) {
            node.classList.remove(DomClasses.INVISIBLE);
        }
    }
}

export default Comment;
export const HeightInputMapper = {
    'inputfield-37': 'h1',
    'inputfield-38': 'h2',
    'inputfield-39': 'h3',
    'inputfield-40': 'h4',
    'inputfield-55': 'h5',
    'inputfield-56': 'h6',
    'inputfield-34': 'h7',
    'inputfield-44': 'h1',
    'inputfield-45': 'h2',
    'inputfield-47': 'h3',
    'inputfield-48': 'h4',
    'inputfield-57': 'h5',
    'inputfield-58': 'h6',
    'inputfield-49': 'h7',
};

export const Height3DMapper = {
    'inputfield-50': 'h1',
    'inputfield-51': 'h2',
    'inputfield-52': 'h3',
    'inputfield-53': 'h4',
    'inputfield-59': 'h5',
    'inputfield-60': 'h6',
    'inputfield-54': 'h7',
};
import {DomClasses, DomIds} from '../utils/DomConstants';
import KosyErrorWidth from '../helper/KosyErrorWidth';

class Description {
    render(topicDescription, ajaxResult)
    {
        let kosyClass = DomClasses.HIDDEN;
        if (ajaxResult.hasOwnProperty('generalInfos') || ajaxResult.hasOwnProperty('kosyInfos')) {
            kosyClass = '';
        }

        const node = document.querySelector('.' + DomClasses.TOPIC_DESCRIPTION);
        node.innerHTML = `<div id="${DomIds.TOPIC_QUESTION}" class="col-md-8">${topicDescription}</div>`;

        KosyErrorWidth.calculateWidth();

        node.innerHTML +=
            `<div id="kosy" class="col-md-4 ${kosyClass}">
                <div id="${DomIds.KOSY_IMAGE}">
                    <img src="/images/layout/Kosy_info.png" class="kosy-image" alt="Kosy Info">
                    <span id="info-text" class="kosy-image">Für mehr Infos,<br/>frag Kosy</span>
                </div>
                <div id="${DomIds.KOSY_ERROR}" class="error-message hidden"></div>
                <div id="kosy-image-error" class="hidden">
                    <img src="/images/layout/Kosy_error.png" alt="Kosy Fehler">
                </div>
            </div>`;
    }
}

export default Description;
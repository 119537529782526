import {DataAttributes, DomIds, TopicPositions} from '../utils/DomConstants';
import {DomUtils} from '../utils/DomUtils';
import {Height3DMapper, HeightInputMapper} from '../utils/HeightInputMapper';
import Fetch from './Fetch';

class DrawingSketchConnector {
    static setEventListenersToHeightInputs() {
        const h1Node = document.getElementById(DomIds.Height_H1);
        const h2Node = document.getElementById(DomIds.Height_H2);
        const h3Node = document.getElementById(DomIds.Height_H3);
        const h4Node = document.getElementById(DomIds.Height_H4);
        const h5Node = document.getElementById(DomIds.Height_H5);
        const h6Node = document.getElementById(DomIds.Height_H6);
        const h7Node = document.getElementById(DomIds.Height_H7);

        if (h1Node) {
            h1Node.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h1Node.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h2Node) {
            h2Node.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h2Node.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h3Node) {
            h3Node.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h3Node.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h4Node) {
            h4Node.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h4Node.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h5Node) {
            h5Node.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h5Node.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h6Node) {
            h6Node.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h6Node.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h7Node) {
            h7Node.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h7Node.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }

        const h1CoveringNode = document.getElementById(DomIds.Height_COVERING_SLOPE_H1);
        const h2CoveringNode = document.getElementById(DomIds.Height_COVERING_SLOPE_H2);
        const h3CoveringNode = document.getElementById(DomIds.Height_COVERING_SLOPE_H3);
        const h4CoveringNode = document.getElementById(DomIds.Height_COVERING_SLOPE_H4);
        const h5CoveringNode = document.getElementById(DomIds.Height_COVERING_SLOPE_H5);
        const h6CoveringNode = document.getElementById(DomIds.Height_COVERING_SLOPE_H6);
        const h7CoveringNode = document.getElementById(DomIds.Height_COVERING_SLOPE_H7);

        if (h1CoveringNode) {
            h1CoveringNode.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h1CoveringNode.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h2CoveringNode) {
            h2CoveringNode.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h2CoveringNode.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h3CoveringNode) {
            h3CoveringNode.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h3CoveringNode.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h4CoveringNode) {
            h4CoveringNode.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h4CoveringNode.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h5CoveringNode) {
            h5CoveringNode.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h5CoveringNode.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h6CoveringNode) {
            h6CoveringNode.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h6CoveringNode.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
        if (h7CoveringNode) {
            h7CoveringNode.addEventListener('keyup', DrawingSketchConnector.changeHiddenDrawingHeightInput);
            h7CoveringNode.addEventListener('change', DrawingSketchConnector.changeHiddenDrawingHeightInput);
        }
    }

    static changeHiddenDrawingHeightInput(event) {
        const id = event.target.id;
        DrawingSketchConnector.mapInputValues(id, event.target.value);
    }

    static mapInputValues(id, value) {
        const drawingHeightNode = DomUtils.getElementByAttributeAndValue(DataAttributes.Slope, HeightInputMapper[id]);
        if (drawingHeightNode) {
            drawingHeightNode.value = value;
        }

        DomUtils.recalculateDrawing();
    }

    static map3DHeightValues(topicUid) {
        if (topicUid === 'T_A_SLOPE_SUMMARY') {
            const inputFieldIds = [
                DomIds.SLOPE_SUMMARY_H1,
                DomIds.SLOPE_SUMMARY_H2,
                DomIds.SLOPE_SUMMARY_H3,
                DomIds.SLOPE_SUMMARY_H4,
                DomIds.SLOPE_SUMMARY_H5,
                DomIds.SLOPE_SUMMARY_H6,
                DomIds.SLOPE_SUMMARY_H7,
            ];

            inputFieldIds.forEach((id) => {
                const inputNode = document.getElementById(id);
                const input3DNode = document.getElementById(Height3DMapper[id]);
                if (inputNode && input3DNode) {
                    input3DNode.value = parseInt(inputNode.value) * 5;
                }
            });
        }
    }

    static setDrawingHeightValuesToDefault() {
        const headers = {
            'x-pretty-print': '2'
        };
        const fetcher = new Fetch('slope-direction/get-system-finder-height-value', 'GET', headers);
        fetcher.proceed((response) => {
            const drawingHeightInputs = [
                DomIds.DRAWING_H1,
                DomIds.DRAWING_H2,
                DomIds.DRAWING_H3,
                DomIds.DRAWING_H4,
                DomIds.DRAWING_H5,
                DomIds.DRAWING_H6,
            ];
            drawingHeightInputs.forEach(drawingHeightInput => {
                const node = document.getElementById(drawingHeightInput);
                if (node) {
                    node.value = response;
                }
            });
        });
    }

    setUndergroundSlopeInitValues() {
        if (document.getElementById(DomIds.ANSWER_INDIVIDUAL_SLOPE).checked) {
            this.setHeightInputValue(DomIds.Height_H1);
            this.setHeightInputValue(DomIds.Height_H2);
            this.setHeightInputValue(DomIds.Height_H3);
            this.setHeightInputValue(DomIds.Height_H4);
            this.setHeightInputValue(DomIds.Height_H5);
            this.setHeightInputValue(DomIds.Height_H6);
        }

        const withSlopeNode = document.getElementById(DomIds.ANSWER_WITH_SLOPE);
        const withOutSlopeNode = document.getElementById(DomIds.ANSWER_WITHOUT_SLOPE);
        const renderSlopeLabelsNode = document.getElementById(DomIds.RenderSlopeLabels);

        if (withOutSlopeNode.checked) {
            renderSlopeLabelsNode.checked = false;
        }

        if (withSlopeNode.checked) {
            renderSlopeLabelsNode.checked = true;
        }

        const drawingWithOutFloorDrain = document.getElementById(DomIds.DRAWING_WITHOUT_FLOOR_DRAIN);
        drawingWithOutFloorDrain.checked = true;
        DomUtils.recalculateDrawing();
    }

    setFloorDrainInitValues() {
        const floorDrainHeightNode = document.getElementById(DomIds.Height_H7);
        if (floorDrainHeightNode) {
            DrawingSketchConnector.mapInputValues(DomIds.Height_H7, floorDrainHeightNode.value);
        }

        const headers = {
            'x-pretty-print': '2'
        };
        const fetcher = new Fetch('slope-direction/get-underground-slope-values', 'GET', headers);
        fetcher.proceed((response) => {
            for (let responseKey in response) {
                if (response.hasOwnProperty(responseKey)) {
                    DrawingSketchConnector.mapInputValues(responseKey, response[responseKey]);
                }
            }
            const withFloorDrain = document.getElementById(DomIds.ANSWER_WITH_FLOOR_DRAIN);
            const renderSlopeLabelsNode = document.getElementById(DomIds.RenderSlopeLabels);
            renderSlopeLabelsNode.checked = withFloorDrain.checked;
            const widthFloorDrainNode = document.getElementById(DomIds.DRAWING_WITH_FLOOR_DRAIN);
            widthFloorDrainNode.checked = withFloorDrain.checked;

            DomUtils.recalculateDrawing();
        });
    }

    setCoveringSlopeInitValues() {
        const renderSlopeLabelsNode = document.getElementById(DomIds.RenderSlopeLabels);
        if (document.getElementById(DomIds.ANSWER_INDIVIDUAL_COVERING_SLOPE).checked) {
            this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H1);
            this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H2);
            this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H3);
            this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H4);
            this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H5);
            this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H6);
            this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H7);
        }

        const headers = {
            'x-pretty-print': '2'
        };
        const fetcher = new Fetch('slope-direction/get-floor-drain-values', 'GET', headers);
        fetcher.proceed((response) => {
            const withFloorDrainWithoutLabels = document.getElementById(DomIds.DRAWING_WITH_FLOOR_DRAIN_WITHOUT_LABELS);
            const withoutFloorDrain = document.getElementById(DomIds.DRAWING_WITHOUT_FLOOR_DRAIN);
            const floorDrainX = document.getElementById(DomIds.DRAWING_FLOOR_DRAIN_X);
            const floorDrainY = document.getElementById(DomIds.DRAWING_FLOOR_DRAIN_Y);

            if (response.withFloorDrain) {
                withFloorDrainWithoutLabels.checked = true;
            } else {
                withoutFloorDrain.checked = true;
            }

            floorDrainX.value = response.floorDrainX;
            floorDrainY.value = response.floorDrainY;

            const followUnderGroundSlope = document.getElementById(DomIds.ANSWER_FOLLOWING_COVERING_SLOPE);
            if (followUnderGroundSlope && followUnderGroundSlope.checked) {
                this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H1, response[DomIds.Height_H1]);
                this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H2, response[DomIds.Height_H2]);
                this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H3, response[DomIds.Height_H3]);
                this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H4, response[DomIds.Height_H4]);
                this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H5, response[DomIds.Height_H5]);
                this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H6, response[DomIds.Height_H6]);
                this.setHeightInputValue(DomIds.Height_COVERING_SLOPE_H7, response[DomIds.Height_H7]);

                renderSlopeLabelsNode.checked = true;
            }

            DomUtils.recalculateDrawing();
        });
    }

    setSummarySlopeInitValues() {
        const headers = {
            'x-pretty-print': '2'
        };
        const fetcher = new Fetch('slope-direction/get-floor-drain-values', 'GET', headers);
        fetcher.proceed((response) => {
            const withFloorDrainLabels = document.getElementById(DomIds.DRAWING_WITH_FLOOR_DRAIN);
            const withoutFloorDrain = document.getElementById(DomIds.DRAWING_WITHOUT_FLOOR_DRAIN);
            const floorDrainX = document.getElementById(DomIds.DRAWING_FLOOR_DRAIN_X);
            const floorDrainY = document.getElementById(DomIds.DRAWING_FLOOR_DRAIN_Y);

            if (response.withFloorDrain) {
                withFloorDrainLabels.checked = true;
            } else {
                withoutFloorDrain.checked = true;
            }

            floorDrainX.value = response.floorDrainX;
            floorDrainY.value = response.floorDrainY;


            DomUtils.recalculateDrawing();
        });
    }

    setHeightInputValue(id, value) {
        const heightInputNode = document.getElementById(id);
        let valueToMap = 0;
        if (value !== undefined) {
            valueToMap = value;
        } else if (heightInputNode) {
            valueToMap = heightInputNode.value;
        }

        if (heightInputNode) {
            DrawingSketchConnector.mapInputValues(id, valueToMap);
        }
    }

    showHideFloorDrainAndArrows(show) {
        if (show) {
            const withFloorDrain = document.getElementById(DomIds.DRAWING_WITH_FLOOR_DRAIN);
            withFloorDrain.checked = show;
        } else {
            const withoutFloorDrain = document.getElementById(DomIds.DRAWING_WITHOUT_FLOOR_DRAIN);
            withoutFloorDrain.checked = !show;
        }
        const renderSlopeLabelsNode = document.getElementById(DomIds.RenderSlopeLabels);
        renderSlopeLabelsNode.checked = show;
        DomUtils.recalculateDrawing();

    }

    slopeValuesShouldBeRendered(topicId) {
        const node = document.getElementById(DomIds.RenderSlopeLabels);
        if (node) {
            node.checked = topicId === TopicPositions.T_A_SLOPE
                || topicId === TopicPositions.T_A_FLOOR_DRAIN
                || topicId === TopicPositions.T_A_SLOPE_COVERING
                || topicId === TopicPositions.T_A_SLOPE_SUMMARY;
        }
    }

    hideShowSlopeResultsInDrawing(answerId) {
        const renderSlope = document.getElementById(DomIds.RenderSlopeLabels);

        switch (answerId) {
            case DomIds.ANSWER_WITHOUT_SLOPE:
            case DomIds.ANSWER_WITHOUT_COVERING_SLOPE:
                renderSlope.checked = false;
                DomUtils.recalculateDrawing();
                break;
            case DomIds.ANSWER_WITH_SLOPE:
            case DomIds.ANSWER_INDIVIDUAL_SLOPE:
            case DomIds.ANSWER_ONE_SIDED_SLOPE:
            case DomIds.ANSWER_TWO_SIDED_SLOPE:
            case DomIds.ANSWER_INDIVIDUAL_COVERING_SLOPE:
            case DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE:
            case DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE:
                renderSlope.checked = true;
                DomUtils.recalculateDrawing();
                break;
        }
    }

    showHideTwoPentSlopeLine() {
        const underground2PentSlopeAnswer = document.getElementById(DomIds.ANSWER_TWO_SIDED_SLOPE);
        const covering2PentSlopeAnswer = document.getElementById(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE);
        const render2PentSlopeLineBtn = document.getElementById(DomIds.Render2PentSlopeLine);

        if ((render2PentSlopeLineBtn && underground2PentSlopeAnswer)) {
            render2PentSlopeLineBtn.checked = underground2PentSlopeAnswer.checked;
            DomUtils.recalculateDrawing();
        }
        if ((render2PentSlopeLineBtn && covering2PentSlopeAnswer)) {
            render2PentSlopeLineBtn.checked = covering2PentSlopeAnswer.checked;
            DomUtils.recalculateDrawing();
        }
    }
}

export default DrawingSketchConnector;
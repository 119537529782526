import {DomIds, TopicIds} from '../utils/DomConstants';

class SlopeSketch {
    constructor(topicId) {
        this.topicId = topicId;
    }

    render() {
        const slopeSketch = document.getElementById(DomIds.SLOPE_SKETCH);
        if (this.shouldBeRendered()) {
            if (slopeSketch) {
                slopeSketch.remove();
            }

            const questionContainerContent = document.querySelector('#question-container > .content-field');
            const imagePath = this.getImagesPath();

            const slopeContainer = document.createElement('div');
            slopeContainer.id = DomIds.SLOPE_SKETCH;

            const imageContainer = document.createElement('img');
            imageContainer.src = imagePath;
            imageContainer.title = imagePath;

            slopeContainer.append(imageContainer);

            questionContainerContent.appendChild(slopeContainer);
        }
    }

    shouldBeRendered() {
        return this.topicId === TopicIds.SLOPE_UNDERGROUND
            || this.topicId === TopicIds.FLOOR_DRAIN
            ||this.topicId === TopicIds.SLOPE_PLATES;
    }

    getImagesPath() {
        const baseUrl = window.location.origin + '/images/slope/';

        switch (this.topicId) {
        case TopicIds.SLOPE_UNDERGROUND:
            return baseUrl + 'slope-underground.png';
        case TopicIds.FLOOR_DRAIN:
            return baseUrl + 'floor-drain.png';
        case TopicIds.SLOPE_PLATES:
            return baseUrl + 'slope-plates.png';
        }
    }
}

export default SlopeSketch;
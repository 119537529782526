import {DomIds} from '../utils/DomConstants';

class AnswerHelper {
    static shouldVisibilityOfInputFieldsBeChecked(answerId) {
        if (answerId === DomIds.ANSWER_WITH_SLOPE) {
            const individualSlopeNode = document.getElementById(DomIds.ANSWER_INDIVIDUAL_SLOPE);
            const onePentSlopeNode = document.getElementById(DomIds.ANSWER_ONE_SIDED_SLOPE);
            const twoPentSlopeNode = document.getElementById(DomIds.ANSWER_TWO_SIDED_SLOPE);

            if (individualSlopeNode && individualSlopeNode.checked) {
                return false;
            }
            if (onePentSlopeNode && onePentSlopeNode.checked) {
                return false;
            }
            if (twoPentSlopeNode && twoPentSlopeNode.checked) {
                return false;
            }
        }
        if (answerId === DomIds.ANSWER_WITH_COVERING_SLOPE) {
            const individualCoveringSlopeNode = document.getElementById(DomIds.ANSWER_INDIVIDUAL_COVERING_SLOPE);
            const onePentCoveringSlopeNode = document.getElementById(DomIds.ANSWER_ONE_SIDED_COVERING_SLOPE);
            const twoPentCoveringSlopeNode = document.getElementById(DomIds.ANSWER_TWO_SIDED_COVERING_SLOPE);

            if (individualCoveringSlopeNode && individualCoveringSlopeNode.checked) {
                return false;
            }
            if (onePentCoveringSlopeNode && onePentCoveringSlopeNode.checked) {
                return false;
            }
            if (twoPentCoveringSlopeNode && twoPentCoveringSlopeNode.checked) {
                return false;
            }
        }
        return true;
    }
}

export default AnswerHelper;
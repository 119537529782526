import {AnswerStyles, DomIds} from '../utils/DomConstants';
import {DomUtils} from '../utils/DomUtils';

class Answer {
    constructor(questionId, val, answerStyle, checked) {
        this.questionId = questionId;
        this.val = val;
        this.answerStyle = answerStyle;
        this.checked = checked;
        this.node = document.getElementById(this.questionId);
        this.setName();
    }

    setName() {
        if (this.val.multiple === 0) {
            this.name = this.questionId;
        } else {
            this.name = this.val['uid'];
        }
    }

    render(checkDoorsWindowsCountWithChange, columnSize, columnHidden, commentVisibility, title, answerStatus, hidden, image, caption, imageDescription) {
        if (this.val.multiple === 0) {
            if (this.answerStyle === AnswerStyles.DROP_DOWN_LIST) {
                this._renderDropDownList(checkDoorsWindowsCountWithChange);
            } else {
                this._renderImageAnswer(columnSize, columnHidden, commentVisibility, title, answerStatus, hidden, image, caption, imageDescription);

                $('#answer-' + this.val['id']).css('display', 'none');
            }
        } else {
            this._renderCheckbox();
        }
    }

    _renderImageAnswer(columnSize, columnHidden, commentVisibility, title, answerStatus, hidden, image, caption, imageDescription) {
        this.node.innerHTML += `
            <div class="col-md-${columnSize} columnLayout ${columnHidden}">
                <input type="radio" name="${this.name}" id="answer-${this.val['id']}" class="${commentVisibility}" value="${this.val['id']}" ${this.checked}/>
                <div class="answer-title" data-answertitle="${title.toLowerCase()}">${title}</div>
                <label for="answer-${this.val['id']}" data-label="answer-${this.val['id']}" class="${this.name} ${answerStatus} ${this.answerStyle} ${hidden}">
                    ${image}
                    ${caption}
                    <div id="image-description">${imageDescription}</div>
                </label>
            </div>`;
    }

    _renderCheckbox() {
        this.node.innerHTML +=
            `<div>
                <input type="checkbox" name="Q_${this.name}" id="answer-${this.val['id']}" 'value="${this.val['id']}" ${this.checked} ${this.val['readonly']}/>
                ${this.val['name']}
            </div>`;
    }

    _renderDropDownList(checkDoorsWindowsCountWithChange) {
        if (this.val['pos'] === 1) {
            this.node.innerHTML += `<select id="${this.questionId}-select" class="dropdown-items" name="${this.name}" ></select>`;

            const answerWithoutDoorWindow = document.getElementById(DomIds.ANSWER_DOOR_WINDOW_ID);
            if (answerWithoutDoorWindow) {
                checkDoorsWindowsCountWithChange();
            }
        }

        const selectNode = document.getElementById(this.questionId + '-select');
        const html = `
                 <option
                  value="${this.val['id']}"
                  data-count="${this.val['name']}"
                  class="${this.name} ${this.answerStyle}"
                  data-label="answer-${this.val['id']}">${this.val['name']}</option>`;

        selectNode.insertAdjacentHTML('beforeend', html);
    }

    setDropDownListValue() {
        if (this.answerStyle === AnswerStyles.DROP_DOWN_LIST) {
            const option = DomUtils.getElementByAttributeAndValue('label', 'answer-' + this.val.id);
            const selectNode = document.getElementById(this.questionId + '-select');
            selectNode.value = option.value;
        }
    }
}

export default Answer;
import {DomClasses, DomIds} from '../utils/DomConstants';

class KosyInfo {
    static delete() {
        const node = document.getElementById(DomIds.KOSY_INFO);
        if (node) {
            node.innerHTML = '';
        }
    }

    render(kosyInfos) {
        if (this.checkKosyInfosLength(kosyInfos)) {
            this.removeHidden();
            // alert(Object.keys(kosyInfos).length);
            const node = document.getElementById(DomIds.KOSY_INFO);
            for (const kosyInfosKey in kosyInfos) {
                node.innerHTML += `<span class="blue-color">${kosyInfosKey}: </span> ${kosyInfos[kosyInfosKey]} <br>`;
            }
            this.showScrollBar(0);
            return true;
        }
        return false;
    }

    renderGeneralInfos(kosyInfos) {
        if(this.checkKosyInfosLength(kosyInfos)) {
            this.removeHidden();
            const node = document.getElementById(DomIds.KOSY_INFO);
            for (const kosyInfosKey in kosyInfos) {
                node.innerHTML += kosyInfos[kosyInfosKey] + '<br>';
            }
            const spanTag = document.getElementById(DomIds.KOSY_INFO).getElementsByTagName('span');
            this.showScrollBar(spanTag.length);
            return true;
        }
        return false;
    }

    checkKosyInfosLength(kosyInfos) {
        if (kosyInfos === undefined || kosyInfos === null) {
            return false;
        }

        return Object.keys(kosyInfos).length > 0 || kosyInfos.length > 0;
    }

    removeHidden() {
        const image = document.getElementById(DomIds.KOSY_IMAGE);
        if (image) {
            image.classList.remove(DomClasses.HIDDEN);
        }
    }
    showScrollBar(kosyInfosLength) {
        const kosy = document.getElementById(DomIds.KOSY_INFO);
        const screenHeight = screen.height;
        if(kosyInfosLength > 4 && screenHeight <= 875){
            kosy.classList.add(DomClasses.SHOW_SCROLL);
        } else {
            kosy.classList.remove(DomClasses.SHOW_SCROLL);
        }
    }
    hide(ordered) {
        if (!this.checkKosyInfosLength(ordered['kosyInfos']) && !this.checkKosyInfosLength(ordered['generalInfos'])) {
            $('#kosy-image').addClass('hidden');
            $('#kosy-info').html('');
            $('#kosy-info').addClass('hidden');
        }
    }
}

export default KosyInfo;
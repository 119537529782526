import {DomClasses, DomIds, TopicIds} from '../utils/DomConstants';
import {ShapeConstants} from '../utils/ShapeConstants';
import DeckHelper from '../helper/DeckHelper';

class HintFloorDrain {
    render(topicId) {
        if (topicId === TopicIds.FLOOR_DRAIN) {
            const topicContentsNode = document.getElementById(DomIds.TOPIC_INPUTS);
            const html = `
                            <div id="${DomIds.HINT_FLOOR_DRAIN_DISTANCE}" class="hint-only-one-answer">
                                <div>
                                    Bei Bodenabläufen sollten die Achsabstände ≥ <span class="blue-color">400</span> mm zu Hauswänden, Brüstungen und freien Belagsrändern sein. Brüstungsspeier setze bitte direkt an die Brüstung / Attika. Wenn Du Sicher bist fahre bitte fort. Für mehr Infos, frag KOSY.
                                </div>
                            </div>`;
            topicContentsNode.insertAdjacentHTML('beforeend', html);
        }

        HintFloorDrain.hideShow();
    }

    static hideShow() {
        const hintNode = document.getElementById(DomIds.HINT_FLOOR_DRAIN_DISTANCE);
        if (hintNode) {
            if (HintFloorDrain.isFloorDrainNearEdge()) {
                hintNode.classList.remove(DomClasses.HIDDEN);
            } else {
                hintNode.classList.add(DomClasses.HIDDEN);
            }
        }

    }

    static isFloorDrainNearEdge() {
        const floorDrainXInput = document.getElementById(DomIds.FLOOR_DRAIN_X);
        const floorDrainYInput = document.getElementById(DomIds.FLOOR_DRAIN_Y);

        if (floorDrainXInput && floorDrainYInput) {
            let floorDrainX = floorDrainXInput.value;
            let floorDrainY = floorDrainYInput.value;

            const maxDistanceFromEdge = 400;
            const a1 = parseInt(document.getElementById(DomIds.A1).value);
            const a2 = parseInt(document.getElementById(DomIds.A2).value);
            const b1 = parseInt(document.getElementById(DomIds.B1).value);
            const b2 = parseInt(document.getElementById(DomIds.B2).value);


            switch (DeckHelper.getShape()) {
                case ShapeConstants.L_SHAPE:
                    return HintFloorDrain.isFloorDrainNearEdgeLShape(
                        a1,
                        a2,
                        b1,
                        b2,
                        floorDrainX,
                        floorDrainY,
                        maxDistanceFromEdge
                    );
                case ShapeConstants.L_SHAPE_90:
                    return HintFloorDrain.isFloorDrainNearEdgeLShape90(
                        a1,
                        a2,
                        b1,
                        b2,
                        floorDrainX,
                        floorDrainY,
                        maxDistanceFromEdge
                    );
                case ShapeConstants.L_SHAPE_180:
                    return HintFloorDrain.isFloorDrainNearEdgeLShape180(
                        a1,
                        a2,
                        b1,
                        b2,
                        floorDrainX,
                        floorDrainY,
                        maxDistanceFromEdge
                    );
                case ShapeConstants.L_SHAPE_270:
                    return HintFloorDrain.isFloorDrainNearEdgeLShape270(
                        a1,
                        a2,
                        b1,
                        b2,
                        floorDrainX,
                        floorDrainY,
                        maxDistanceFromEdge
                    );
            }

            if (DeckHelper.getShape() === ShapeConstants.SQUARE) {
                return HintFloorDrain.isFloorDrainNearEdgeRectangle(a1, a1, floorDrainX, floorDrainY, maxDistanceFromEdge);
            }

            return HintFloorDrain.isFloorDrainNearEdgeRectangle(a1, b1, floorDrainX, floorDrainY, maxDistanceFromEdge);
        }

        return false;

    }

    static isFloorDrainNearEdgeRectangle(a, b, floorDrainXInput, floorDrainYInput, maxDistanceFromEdge) {
        const verticalLeftEdge = 0;
        const verticalLeftEdgeDistance = maxDistanceFromEdge;
        const verticalRightEdge = a;
        const verticalRightEdgeDistance = a - maxDistanceFromEdge;

        const horizontalTopEdge = 0;
        const horizontalTopEdgeDistance = maxDistanceFromEdge;
        const horizontalBottomEdge = b;
        const horizontalBottomEdgeDistance = b - maxDistanceFromEdge;

        if (floorDrainXInput >= verticalLeftEdge && floorDrainXInput < verticalLeftEdgeDistance) {
            return true;
        }

        if (floorDrainXInput > verticalRightEdgeDistance && floorDrainXInput <= verticalRightEdge) {
            return true;
        }

        if (floorDrainYInput >= horizontalTopEdge && floorDrainYInput < horizontalTopEdgeDistance) {
            return true;
        }

        return floorDrainYInput > horizontalBottomEdgeDistance && floorDrainYInput <= horizontalBottomEdge;
    }

    static isFloorDrainNearEdgeLShape(a1, a2, b1, b2, floorDrainXInput, floorDrainYInput, maxDistanceFromEdge) {
        const verticalLeftEdge = 0;
        const verticalLeftEdgeDistance = maxDistanceFromEdge;
        const verticalRightEdge = a1 + a2;
        const verticalRightEdgeDistance = verticalRightEdge - maxDistanceFromEdge;
        const verticalCutOutEdge = a1;
        const verticalCutOutEdgeDistance = verticalCutOutEdge - maxDistanceFromEdge;

        const horizontalTopEdge = 0;
        const horizontalTopEdgeDistance = maxDistanceFromEdge;
        const horizontalBottomEdge = b1 + b2;
        const horizontalBottomEdgeDistance = horizontalBottomEdge - maxDistanceFromEdge;
        const horizontalCutOutEdge = b1;
        const horizontalCutOutEdgeDistance = horizontalCutOutEdge + maxDistanceFromEdge;

        if (floorDrainXInput >= verticalLeftEdge && floorDrainXInput < verticalLeftEdgeDistance) {
            return true;
        }

        if (floorDrainXInput > verticalCutOutEdgeDistance && floorDrainXInput <= verticalCutOutEdge && floorDrainYInput <= b1) {
            return true;
        }

        if (floorDrainXInput > verticalRightEdgeDistance && floorDrainXInput <= verticalRightEdge) {
            return true;
        }

        if (floorDrainYInput >= horizontalTopEdge && floorDrainYInput < horizontalTopEdgeDistance) {
            return true;
        }

        if (floorDrainYInput >= horizontalCutOutEdge && floorDrainYInput < horizontalCutOutEdgeDistance && floorDrainXInput > a1) {
            return true;
        }

        return floorDrainYInput > horizontalBottomEdgeDistance && floorDrainYInput <= horizontalBottomEdge;
    }

    static isFloorDrainNearEdgeLShape90(a1, a2, b1, b2, floorDrainXInput, floorDrainYInput, maxDistanceFromEdge) {
        const verticalLeftEdge = 0;
        const verticalLeftEdgeDistance = maxDistanceFromEdge;
        const verticalRightEdgeTop = b1 + b2;
        const verticalRightEdgeDistance = verticalRightEdgeTop - maxDistanceFromEdge;
        const verticalCutOutEdge = b2;
        const verticalCutOutEdgeDistance = verticalCutOutEdge - maxDistanceFromEdge;

        const horizontalTopEdge = 0;
        const horizontalTopEdgeDistance = maxDistanceFromEdge;
        const horizontalBottomEdge = a1 + a2;
        const horizontalBottomEdgeDistance = horizontalBottomEdge - maxDistanceFromEdge;
        const horizontalCutOutEdge = a1;
        const horizontalCutOutEdgeDistance = horizontalCutOutEdge - maxDistanceFromEdge;

        if (floorDrainXInput >= verticalLeftEdge && floorDrainXInput < verticalLeftEdgeDistance) {
            return true;
        }

        if (floorDrainXInput > verticalRightEdgeDistance && floorDrainXInput <= verticalRightEdgeTop && floorDrainYInput <= a1) {
            return true;
        }

        if (floorDrainXInput > verticalCutOutEdgeDistance && floorDrainXInput <= verticalCutOutEdge && floorDrainYInput >= a1) {
            return true;
        }

        if (floorDrainYInput >= horizontalTopEdge && floorDrainYInput < horizontalTopEdgeDistance) {
            return true;
        }
        if (floorDrainYInput >= horizontalCutOutEdgeDistance && floorDrainYInput <= horizontalCutOutEdge && floorDrainXInput > b2) {
            return true;
        }
        if (floorDrainYInput > horizontalBottomEdgeDistance && floorDrainYInput <= horizontalBottomEdge && floorDrainXInput <= b2) {
            return true;
        }
    }

    static isFloorDrainNearEdgeLShape180(a1, a2, b1, b2, floorDrainXInput, floorDrainYInput, maxDistanceFromEdge) {
        const verticalLeftEdge = 0;
        const verticalLeftEdgeDistance = maxDistanceFromEdge;
        const verticalRightEdge = a1 + a2;
        const verticalRightEdgeDistance = verticalRightEdge - maxDistanceFromEdge;
        const verticalCutOutEdge = a2;
        const verticalCutOutEdgeDistance = verticalCutOutEdge + maxDistanceFromEdge;

        const horizontalTopEdge = 0;
        const horizontalTopEdgeDistance = maxDistanceFromEdge;
        const horizontalBottomEdge = b1 + b2;
        const horizontalBottomEdgeDistance = horizontalBottomEdge - maxDistanceFromEdge;
        const horizontalCutOutEdge = b2;
        const horizontalCutOutEdgeDistance = horizontalCutOutEdge - maxDistanceFromEdge;

        if (floorDrainXInput >= verticalLeftEdge && floorDrainXInput < verticalLeftEdgeDistance && floorDrainYInput < b1) {
            return true;
        }
        if (floorDrainXInput > verticalRightEdgeDistance && floorDrainXInput <= verticalRightEdge) {
            return true;
        }
        if (floorDrainXInput >= verticalCutOutEdge && floorDrainXInput < verticalCutOutEdgeDistance && floorDrainYInput > b2) {
            return true;
        }

        if (floorDrainYInput >= horizontalTopEdge && floorDrainYInput < horizontalTopEdgeDistance) {
            return true;
        }
        if (floorDrainYInput > horizontalBottomEdgeDistance && floorDrainYInput <= horizontalBottomEdge && floorDrainXInput >= a2) {
            return true;
        }
        if (floorDrainYInput > horizontalCutOutEdgeDistance && floorDrainYInput <= horizontalCutOutEdge && floorDrainXInput <= a2) {
            return true;
        }
    }

    static isFloorDrainNearEdgeLShape270(a1, a2, b1, b2, floorDrainXInput, floorDrainYInput, maxDistanceFromEdge) {
        const verticalLeftEdge = 0;
        const verticalLeftEdgeDistance = maxDistanceFromEdge;
        const verticalRightEdge = b1 + b2;
        const verticalRightEdgeDistance = verticalRightEdge - maxDistanceFromEdge;
        const verticalCutOutEdge = b1;
        const verticalCutOutEdgeDistance = verticalCutOutEdge + maxDistanceFromEdge;

        const horizontalTopEdge = 0;
        const horizontalTopEdgeDistance = maxDistanceFromEdge;
        const horizontalBottomEdge = a1 + a2;
        const horizontalBottomEdgeDistance = horizontalBottomEdge - maxDistanceFromEdge;
        const horizontalCutOutEdge = a2;
        const horizontalCutOutEdgeDistance = horizontalCutOutEdge + maxDistanceFromEdge;

        if (floorDrainXInput >= verticalLeftEdge && floorDrainXInput < verticalLeftEdgeDistance && floorDrainYInput >= a2) {
            return true;
        }
        if (floorDrainXInput > verticalRightEdgeDistance && floorDrainXInput <= verticalRightEdge) {
            return true;
        }
        if (floorDrainXInput >= verticalCutOutEdge && floorDrainXInput < verticalCutOutEdgeDistance && floorDrainYInput <= a2) {
            return true;
        }

        if (floorDrainYInput >= horizontalTopEdge && floorDrainYInput < horizontalTopEdgeDistance && floorDrainXInput >= b1) {
            return true;
        }
        if (floorDrainYInput > horizontalBottomEdgeDistance && floorDrainYInput <= horizontalBottomEdge) {
            return true;
        }
        if (floorDrainYInput >= horizontalCutOutEdge && floorDrainYInput < horizontalCutOutEdgeDistance && floorDrainXInput <= b1) {
            return true;
        }
    }
}

export default HintFloorDrain;